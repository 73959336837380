import { FormattedMessage } from "react-intl"
import { PrimaryButton, CurveBorderedButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

type SexualityQuestionProps = {
  screen: Screen
  onClick: (formCid: string, reply: string) => void
}

const SEXUALITIES = ["h", "b", "g", "l", "q"]

export function SexualityQuestion(props: SexualityQuestionProps) {
  const {
    screen: { text, image, version, options },
    onClick,
  } = props

  const gender = localStorage.getItem("gender")
  const resolvedOptions = (options || SEXUALITIES).filter((value) =>
    gender === "m" ? value !== "l" : value !== "g",
  )

  const selectSexuality = (sexuality: string) => {
    localStorage.setItem("sexuality", sexuality)
    onClick("sexuality", sexuality)
  }

  switch (version) {
    case "map":
      return (
        <div className="flex flex-col justify-between items-center h-full">
          {image && (
            <img
              src={image}
              width="208.83"
              height="168"
              className="inline-block my-6 mx-auto"
            />
          )}
          {text && (
            <div className="mb-6 font-serif text-2xl tracking-[2px] text-center uppercase">
              <FormattedMessage id={text} />
            </div>
          )}
          <div className="grid grid-rows-2 grid-cols-2 gap-2 w-full">
            {resolvedOptions.map((sexuality) => (
              <CurveBorderedButton
                className="h-[44px] w-full px-1"
                key={sexuality}
                onClick={() => selectSexuality(sexuality)}
              >
                <FormattedMessage id={`v3.option.sexuality.${sexuality}`} />
              </CurveBorderedButton>
            ))}
          </div>
        </div>
      )
    default:
      return (
        <div className="flex flex-col justify-between items-center h-full">
          <QuestionTitle id={text} />
          <div className="pb-4 grow flex items-center height-730:max-h-[230px] height-575:!max-h-[180px]">
            {image && (
              <img
                src={image}
                className="height-730:max-h-[100%] height-730:h-full height-730:mx-auto"
              />
            )}
          </div>
          <div className="flex flex-col items-center justify-center gap-y-1 w-full grow height-650:gap-y-0">
            {resolvedOptions.map((sexuality) => (
              <PrimaryButton
                key={sexuality}
                onClick={() => selectSexuality(sexuality)}
              >
                <FormattedMessage id={`sexuality.${sexuality}`} />
              </PrimaryButton>
            ))}
          </div>
        </div>
      )
  }
}
