import type { Screen } from "./types"

export const PAYGATE_CONFIG: Array<Screen[]> = [
  [
    {
      type: "auth",
      textColor: "#000000",
      logoColor: "transparent",
      action: "registration",
      id: "screen_authorization",
      showFacebook: true,
    },
    {
      type: "paygate",
      paygateType: "paymentButtonsTop",
      hideProgressBar: true,
      noMaxHeight: true,
      noMaxWidth: true,
      action: "checkout",
      id: "screen_paygate",
      bgColor: "#000000",
      hideTimer: true,
      bundles: {
        year: {
          us: {
            name: {
              paypal: "pure-web.year.paypal.us",
              cardpay: "pure-web.year.cardpay.us",
            },
            label: "paygate.subscription.year",
          },
          uk: {
            name: {
              paypal: "pure-web.year.paypal.uk",
              cardpay: "pure-web.year.cardpay.uk",
            },
            label: "paygate.subscription.year",
          },
          eu: {
            name: {
              paypal: "pure-web.year.paypal.eu",
              cardpay: "pure-web.year.cardpay.eu",
            },
            label: "paygate.subscription.year",
          },
          row: {
            name: {
              paypal: "pure-web.year.paypal.row",
              cardpay: "pure-web.year.cardpay.row",
            },
            label: "paygate.subscription.year",
          },
        },
        month: {
          us: {
            name: {
              paypal: "pure-web.monthly.paypal.us",
              cardpay: "pure-web.monthly.cardpay.us",
            },
            label: "paygate.subscription.month",
          },
          uk: {
            name: {
              paypal: "pure-web.monthly.paypal.uk",
              cardpay: "pure-web.monthly.cardpay.uk",
            },
            label: "paygate.subscription.month",
          },
          eu: {
            name: {
              paypal: "pure-web.monthly.paypal.eu",
              cardpay: "pure-web.monthly.cardpay.eu",
            },
            label: "paygate.subscription.month",
          },
          row: {
            name: {
              paypal: "pure-web.monthly.paypal.row",
              cardpay: "pure-web.monthly.cardpay.row",
            },
            label: "paygate.subscription.month",
          },
        },
        week: {
          us: {
            name: {
              paypal: "pure-web.weekly.paypal.us",
              cardpay: "pure-web.weekly.cardpay.us",
            },
            label: "paygate.subscription.week",
          },
          uk: {
            name: {
              paypal: "pure-web.weekly.paypal.uk",
              cardpay: "pure-web.weekly.cardpay.uk",
            },
            label: "paygate.subscription.week",
          },
          eu: {
            name: {
              paypal: "pure-web.weekly.paypal.eu",
              cardpay: "pure-web.weekly.cardpay.eu",
            },
            label: "paygate.subscription.week",
          },
          row: {
            name: {
              paypal: "pure-web.weekly.paypal.row",
              cardpay: "pure-web.weekly.cardpay.row",
            },
            label: "paygate.subscription.week",
          },
        },
      },
    },
    {
      type: "payment-success",
      hideProgressBar: true,
      action: "purchase_success",
      noMaxWidth: true,
    },
  ],
]
