import { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { PrimaryButton, CurveBorderedButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen, Gender, Sexuality } from "config/types"

import maleImg from "assets/images/questions/v2-gender-m.webp"
import femaleImg from "assets/images/questions/v2-gender-f.webp"

type RandomLikeQuestionProps = {
  screen: Screen
  step: number
  onClick: (formCid: string) => void
}

const OPTIONS = ["common.yes", "common.no"]
const FEMALE_PHOTO_SEXUALITIES_MAP: Record<Gender, Sexuality[]> = {
  m: ["h", "b", "q", "a"],
  f: ["l"],
}

export function RandomLikeQuestion(props: RandomLikeQuestionProps) {
  const {
    screen: { text, image, version, title, imagesByGender },
    step,
    onClick,
  } = props

  const gender = localStorage.getItem("gender") as Gender
  const sexuality = localStorage.getItem("sexuality") as Sexuality

  const imageGender = FEMALE_PHOTO_SEXUALITIES_MAP[gender]?.includes(sexuality)
    ? "f"
    : "m"

  const images = imagesByGender?.[imageGender]
  const src = useMemo(
    () => images?.[Math.round(Math.random() * (images.length - 1))],
    [step],
  )

  switch (version) {
    case "map":
    default:
      return (
        <div className="flex flex-col justify-between items-center h-full">
          {src && (
            <img
              src={src}
              width="217"
              height="168"
              className="inline-block my-[52px] mx-auto"
            />
          )}
          {text && (
            <div className="mb-6 font-serif text-2xl tracking-[2px] text-center uppercase">
              <FormattedMessage id={text} />
            </div>
          )}
          <div className="flex flex-row items-center justify-center gap-x-2 w-full">
            {OPTIONS.map((option) => (
              <CurveBorderedButton
                className="h-[44px] w-full"
                key={option}
                onClick={() => onClick(option)}
              >
                <FormattedMessage id={option} />
              </CurveBorderedButton>
            ))}
          </div>
        </div>
      )
  }
}
