import "leaflet/dist/leaflet.css"
import "./index.css"

import { useEffect, useState, useMemo } from "react"
import axios from "axios"
import L from "leaflet"
import type { LatLngExpression } from "leaflet"
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet"

import { PhotoMarker } from "./photo-marker"
import { isDesktop } from "lib/device"

import { MARKERS } from "./constants"

type GeoData = { lat?: string; lng?: string }

export type MapProps = {
  className?: string
}

const LAT_PER_PIXEL_ZOOM_15 = 2.133e-5
const LNG_PER_PIXEL_ZOOM_15 = 4.103e-5

// You can edit this constants to change density of profiles on the map
const MARKER_POSITIONS_AREA_MULTIPLIER = 1.5
const MARKER_SIZE = 150

function rectanglesIntersect(
  a: [number, number, number, number],
  b: [number, number, number, number],
) {
  const [minAx, minAy, maxAx, maxAy] = a
  const [minBx, minBy, maxBx, maxBy] = b
  const aLeftOfB = maxAx < minBx
  const aRightOfB = minAx > maxBx
  const aAboveB = minAy > maxBy
  const aBelowB = maxAy < minBy

  return !(aLeftOfB || aRightOfB || aAboveB || aBelowB)
}

export function Map({ className }: MapProps) {
  const [geo, setGeo] = useState<GeoData>({})
  const parsedGeo: LatLngExpression = [
    Number(geo.lat) || 52.520008,
    Number(geo.lng) || 13.404954,
  ]
  const screenLat = LAT_PER_PIXEL_ZOOM_15 * window.innerHeight
  const screenLng = LNG_PER_PIXEL_ZOOM_15 * window.innerWidth
  const latMarkerStep = LAT_PER_PIXEL_ZOOM_15 * MARKER_SIZE
  const lngMarkerStep = LNG_PER_PIXEL_ZOOM_15 * MARKER_SIZE

  const bottomLeftPosition: LatLngExpression = [
    parsedGeo[0] - screenLat * 0.5 * MARKER_POSITIONS_AREA_MULTIPLIER,
    parsedGeo[1] - screenLng * 0.5 * MARKER_POSITIONS_AREA_MULTIPLIER,
  ]

  const randomizedMarkers = useMemo(() => {
    const result: {
      position: LatLngExpression
      data: (typeof MARKERS)[number]
    }[] = []

    mainCycle: for (const data of MARKERS) {
      let markerData
      let success = false
      let attempts = 0

      randomCoordsCycle: do {
        attempts += 1

        const position: LatLngExpression = [
          bottomLeftPosition[0] +
            Math.random() * screenLat * MARKER_POSITIONS_AREA_MULTIPLIER,
          bottomLeftPosition[1] +
            Math.random() * screenLng * MARKER_POSITIONS_AREA_MULTIPLIER,
        ]

        markerData = {
          position,
          data,
        }

        for (const comparedMarker of result) {
          const compared: [number, number, number, number] = [
            // @ts-ignore
            comparedMarker.position[1] - lngMarkerStep / 2,
            // @ts-ignore
            comparedMarker.position[0] - latMarkerStep / 2,
            // @ts-ignore
            comparedMarker.position[1] + lngMarkerStep / 2,
            // @ts-ignore
            comparedMarker.position[0] + latMarkerStep / 2,
          ]

          const current: [number, number, number, number] = [
            position[1] - lngMarkerStep / 2,
            position[0] - latMarkerStep / 2,
            position[1] + lngMarkerStep / 2,
            position[0] + latMarkerStep / 2,
          ]

          if (rectanglesIntersect(compared, current)) {
            continue randomCoordsCycle
          }
        }

        success = true
      } while (!success && attempts < 100)

      result.push(markerData)
    }

    return result
  }, [geo])

  useEffect(() => {
    axios("https://pure.app/content/api/geo").then((data) => {
      setGeo(data.data)
    })
  }, [])

  if (!geo.lat) {
    return null
  }

  const handleClick = (idx: number) => {
    if (isDesktop) {
      return
    }

    const openedClassName = "photo-marker_container__opened"
    const markerNode = document.querySelector(`#pure-photo-marker-${idx}`)

    if (markerNode?.classList.contains(openedClassName)) {
      markerNode?.classList.remove(openedClassName)
    } else {
      const prevMarkerNode = document.querySelector("." + openedClassName)

      prevMarkerNode?.classList.remove(openedClassName)
      markerNode?.classList.add(openedClassName)
    }
  }

  return (
    <MapContainer
      className={className}
      center={parsedGeo}
      zoom={15}
      zoomControl={false}
      doubleClickZoom={false}
      scrollWheelZoom
      touchZoom
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {randomizedMarkers.map((marker, idx) => (
        <Marker
          position={marker.position}
          eventHandlers={{
            click: () => handleClick(idx),
          }}
          icon={PhotoMarker({ ...marker.data, idx })}
        />
      ))}
    </MapContainer>
  )
}
