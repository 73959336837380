import { QuestionTitle } from "components/atoms"
import { PrimaryButton } from "components/molecules"
import { Screen } from "config/types"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

type ReviewStubProps = {
  screen: Screen
  onClick: () => void
}

export function ReviewStub(props: ReviewStubProps) {
  const {
    screen: { image, featuredImage, title },
    onClick,
  } = props

  return (
    <div className="flex flex-col h-full">
      {title && (
        <div>
          <QuestionTitle id={title} />
        </div>
      )}
      <div className={twMerge(!title && "grow")}>
        {image && (
          <img
            src={image}
            className="w-full m-auto mt-8 mb-8 height-650:max-w-[80%] height-530:my-2"
          />
        )}
        {featuredImage && (
          <img
            src={featuredImage}
            className="w-full m-auto mt-8 mb-8 height-650:max-w-[80%] height-530:my-4"
          />
        )}
      </div>
      <div className="w-full text-center pt-3 mb-6">
        <PrimaryButton onClick={onClick}>
          <FormattedMessage id="continue" />
        </PrimaryButton>
      </div>
    </div>
  )
}
