import { twMerge } from "tailwind-merge"

import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react"

import { ReactComponent as BorderX } from "assets/images/border-x.svg"
import { ReactComponent as BorderY } from "assets/images/border-y.svg"

export type CheckboxProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  selected?: boolean
  theme?: "default" | "gray"
}

const checkboxThemes = {
  default: {
    base: {
      border: "text-gray-50",
      button: "bg-gray-50 text-black",
    },
    selected: {
      border: "text-black",
      button: "bg-black text-white",
    },
  },
  gray: {
    base: {
      border: "text-darkgray",
      button: "bg-darkgray text-white",
    },
    selected: {
      border: "text-black",
      button: "bg-black text-white",
    },
  },
}

export function Checkbox(props: CheckboxProps) {
  const {
    className,
    children,
    selected,
    theme = "default",
    ...restProps
  } = props

  const borderColor = selected
    ? checkboxThemes[theme].selected.border
    : checkboxThemes[theme].base.border
  const buttonColor = selected
    ? checkboxThemes[theme].selected.button
    : checkboxThemes[theme].base.button

  const borderXClasses = twMerge("absolute w-full h-1.5", borderColor)
  const borderYClasses = twMerge("absolute w-1.5 h-full", borderColor)

  return (
    <div className={twMerge("p-1.5", className)}>
      <button className={twMerge("relative", buttonColor)} {...restProps}>
        <BorderX className={twMerge(borderXClasses, "-top-[5px]")} />
        <BorderX
          className={twMerge(borderXClasses, "-bottom-[5px] -scale-100")}
        />
        <BorderY
          className={twMerge(borderYClasses, "-left-[5px] -scale-y-100")}
        />
        <BorderY
          className={twMerge(borderYClasses, "-right-[5px] -scale-x-100")}
        />
        <div
          className={twMerge(
            "pt-[5px] pb-1 px-2 font-sans text-base",
            theme === "gray" && "pt-[9px] pb-2 px-4",
          )}
        >
          {children}
        </div>
      </button>
    </div>
  )
}
