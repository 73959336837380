import type { Screen } from "./types"

import GenderImg from "assets/images/questions/gender.png"
import SexualityImg from "assets/images/questions/sexuality.png"
import AgeImg from "assets/images/questions/age.png"
import Age2Img from "assets/images/questions/age-2.png"
import FirstDateImg from "assets/images/questions/first-date.png"
import OpenRelationshipImg from "assets/images/questions/open-relationship.png"
import Turnons2Img from "assets/images/questions/turnons2.png"
import LanguagesImg from "assets/images/questions/languages.png"
import LifestyleImg from "assets/images/questions/lifestyle.png"
import MatchesImg from "assets/images/questions/v2-matches.webp"
import PartnerQualitiesImg from "assets/images/questions/partner-qualities.png"

export const SURVEY_CONFIG_V2: Array<Screen[]> = [
  [
    {
      type: "gender",
      logoColor: "transparent",
      version: "v2",
      title: "v2.gender.title",
      text: "v2.gender.text",
      image: GenderImg,
      id: "screen_gender",
    },
    {
      type: "match-stub",
      logoColor: "transparent",
      text: "v2.matches.1.text",
      progressBarTheme: "white",
      bgColor: "#1A1717",
      textColor: "white",
      buttonTheme: "violet",
      id: "screen_matches_1",
    },
    {
      type: "single",
      text: "age.question1",
      logoColor: "transparent",
      image: AgeImg,
      options: ["18-24", "25-29", "30-39", "40-49", "50+"],
      id: "screen_age",
    },
    {
      type: "match-stub",
      logoColor: "transparent",
      text: "v2.matches.2.text",
      title: "v2.matches.2.title",
      progressBarTheme: "white",
      bgColor: "#1A1717",
      textColor: "white",
      buttonTheme: "violet",
      id: "screen_matches_2",
    },
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.looking_for.text",
      image: Age2Img,
      options: [
        "v2.looking_for.1",
        "v2.looking_for.2",
        "v2.looking_for.3",
        "v2.looking_for.4",
      ],
      id: "screen_looking_for",
    },
  ],
  [
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.ideal_date.text",
      image: FirstDateImg,
      options: ["v2.ideal_date.1", "v2.ideal_date.2", "v2.ideal_date.3"],
      id: "screen_ideal_date",
    },
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.describe_yourself.text",
      image: OpenRelationshipImg,
      options: [
        "v2.describe_yourself.1",
        "v2.describe_yourself.2",
        "v2.describe_yourself.3",
        "v2.describe_yourself.4",
        "v2.describe_yourself.5",
      ],
      id: "screen_describe_yourself",
    },
    {
      type: "match-stub",
      logoColor: "transparent",
      text: "v2.matches.3.text",
      title: "v2.matches.3.title",
      progressBarTheme: "white",
      bgColor: "#1A1717",
      textColor: "white",
      buttonTheme: "violet",
      id: "screen_matches_3",
    },
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.meet_people.text",
      image: Turnons2Img,
      options: [
        "v2.meet_people.1",
        "v2.meet_people.2",
        "v2.meet_people.3",
        "v2.meet_people.4",
      ],
      id: "screen_meet_people",
    },
    {
      type: "multiple",
      logoColor: "transparent",
      text: "v2.people_type.text",
      image: LanguagesImg,
      options: [
        "v2.people_type.1",
        "v2.people_type.2",
        "v2.people_type.3",
        "v2.people_type.4",
        "v2.people_type.5",
        "v2.people_type.6",
      ],
      id: "screen_people_type",
    },
  ],
  [
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.weekend_plan.text",
      image: LifestyleImg,
      options: ["v2.weekend_plan.1", "v2.weekend_plan.2", "v2.weekend_plan.3"],
      id: "screen_weekend_plan",
    },
    {
      type: "match-stub",
      logoColor: "transparent",
      text: "v2.matches.4.text",
      title: "v2.matches.4.title",
      progressBarTheme: "white",
      bgColor: "#1A1717",
      textColor: "white",
      buttonTheme: "violet",
      id: "screen_matches_4",
      image: MatchesImg,
    },
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.favorite_time.text",
      image: SexualityImg,
      options: [
        "v2.favorite_time.1",
        "v2.favorite_time.2",
        "v2.favorite_time.3",
        "v2.favorite_time.4",
      ],
      id: "screen_favorite_time",
    },
    {
      type: "single",
      logoColor: "transparent",
      text: "v2.express_yourself.text",
      image: PartnerQualitiesImg,
      options: [
        "v2.express_yourself.1",
        "v2.express_yourself.2",
        "v2.express_yourself.3",
      ],
      id: "screen_express_yourself",
    },
    {
      type: "analyze",
      logoColor: "transparent",
      options: ["v2.analyze.1", "v2.analyze.2", "v2.analyze.3", "v2.analyze.4"],
      id: "screen_analyzing",
      text: "v2.analyze.text",
    },
    {
      type: "auth",
      textColor: "#000000",
      showFacebook: true,
      logoColor: "transparent",
      action: "registration",
      id: "screen_registration",
    },
    {
      type: "paygate",
      paygateType: "paymentButtonsBottom",
      withEverflow: true,
      hideProgressBar: true,
      noMaxHeight: true,
      noMaxWidth: true,
      action: "checkout",
      id: "screen_paywall",
      bgColor: "#1A1717",
      bundles: {
        year: {
          us: {
            name: {
              paypal: "pure-web.year.paypal.us",
              cardpay: "pure-web.year.cardpay.us",
            },
            label: "paygate.subscription.year",
          },
          uk: {
            name: {
              paypal: "pure-web.year.paypal.uk",
              cardpay: "pure-web.year.cardpay.uk",
            },
            label: "paygate.subscription.year",
          },
          eu: {
            name: {
              paypal: "pure-web.year.paypal.eu",
              cardpay: "pure-web.year.cardpay.eu",
            },
            label: "paygate.subscription.year",
          },
          row: {
            name: {
              paypal: "pure-web.year.paypal.row",
              cardpay: "pure-web.year.cardpay.row",
            },
            label: "paygate.subscription.year",
          },
        },
        month: {
          us: {
            name: {
              paypal: "pure-web.monthly.paypal.us",
              cardpay: "pure-web.monthly.cardpay.us",
            },
            label: "paygate.subscription.month",
          },
          uk: {
            name: {
              paypal: "pure-web.monthly.paypal.uk",
              cardpay: "pure-web.monthly.cardpay.uk",
            },
            label: "paygate.subscription.month",
          },
          eu: {
            name: {
              paypal: "pure-web.monthly.paypal.eu",
              cardpay: "pure-web.monthly.cardpay.eu",
            },
            label: "paygate.subscription.month",
          },
          row: {
            name: {
              paypal: "pure-web.monthly.paypal.row",
              cardpay: "pure-web.monthly.cardpay.row",
            },
            label: "paygate.subscription.month",
          },
        },
        week: {
          us: {
            name: {
              paypal: "pure-web.weekly.paypal.us",
              cardpay: "pure-web.weekly.cardpay.us",
            },
            label: "paygate.subscription.week",
          },
          uk: {
            name: {
              paypal: "pure-web.weekly.paypal.uk",
              cardpay: "pure-web.weekly.cardpay.uk",
            },
            label: "paygate.subscription.week",
          },
          eu: {
            name: {
              paypal: "pure-web.weekly.paypal.eu",
              cardpay: "pure-web.weekly.cardpay.eu",
            },
            label: "paygate.subscription.week",
          },
          row: {
            name: {
              paypal: "pure-web.weekly.paypal.row",
              cardpay: "pure-web.weekly.cardpay.row",
            },
            label: "paygate.subscription.week",
          },
        },
      },
    },
    {
      type: "payment-success",
      hideProgressBar: true,
      action: "purchase_success",
      noMaxWidth: true,
    },
  ],
]
