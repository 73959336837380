import headerImgEn from "assets/images/v55/header_en.webp"
import infoImgEn from "assets/images/v55/info_en.webp"

export const v55ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
  },
  info: {
    en: infoImgEn,
  },
}
