import SoulSDK from "soul-sdk"
import packageJson from "../../package.json"
import { PaymentRegion } from "config/types"

const {
  REACT_APP_ENVIROMENT,
  REACT_APP_APP_NAME,
  REACT_APP_SOUL_API_KEY,
  REACT_APP_SOUL_API_HOST,
} = process.env

let SoulSDKInstance

try {
  SoulSDKInstance = new SoulSDK({
    app: {
      name: REACT_APP_APP_NAME,
      version: packageJson.version,
    },
    apiKey: REACT_APP_SOUL_API_KEY,
    apiHost: REACT_APP_SOUL_API_HOST,
    apiDiscoveryHost: "",
    goddessApiHost: "",
    centrifugoWsHost: "",
    chats: {
      chatWsHost: "",
    },
    requestMetadata: false,
    testing: REACT_APP_ENVIROMENT === "testing",
    anonymousUserServiceKey: "",
  })
} catch (error) {
  SoulSDKInstance = {
    createError: true,
  }
  console.error("SOUL ERROR", error)
}

export const soul = SoulSDKInstance

type DetectRegionFunc = (additionalInfo: {
  clientCountryCode: string
  isClientInEU: string
}) => PaymentRegion

export const detectUserRegion: DetectRegionFunc = (additionalInfo) => {
  if (Number(additionalInfo.isClientInEU) > 0) {
    return "eu"
  }

  if (additionalInfo.clientCountryCode.toLowerCase() === "us") {
    return "us"
  }

  if (additionalInfo.clientCountryCode.toLowerCase() === "uk") {
    return "uk"
  }

  return "row"
}
