import { useState, useEffect, useMemo, useRef } from "react"
import { useIntl } from "react-intl"
import type { TransitionStatus } from "react-transition-group"

import { sendAmplitudeEvent } from "lib/amplitude"
import { ChatData, MessageData } from "../survey"
import { Header } from "./chat-header"
import { TextMessage, ImageMessage, TypingMessage } from "./chat-messages"
import {
  GenderQuestion,
  SingleQuestion,
  ZodiakQuestion,
} from "./chat-questions"
import type { Screen, Gender, Sexuality } from "config/types"

type ChatProps = {
  screen: Screen
  transitionStatus: TransitionStatus
  goNext: () => void
  chatData: ChatData
  updateChatData: (data: Partial<ChatData>) => void
}

const FEMALE_PHOTO_SEXUALITIES_MAP: Record<Gender, Sexuality[]> = {
  m: ["h", "b", "q", "a"],
  f: ["l"],
}

export function Chat({
  screen,
  transitionStatus,
  goNext,
  chatData,
  updateChatData,
}: ChatProps) {
  const intl = useIntl()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef<number>(0)
  const transitionStatusRef = useRef<TransitionStatus>("exited")
  const [messagesBuffer, setMessagesBuffer] = useState<MessageData[]>([])
  const [isEndReached, setIsEndReached] = useState<boolean>(false)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const { questionIdx, messages, gender, sexuality } = chatData

  if (!screen.chatQuestions) {
    console.error(
      "Wrong config - chatQuestions is required for screen === 'chat'",
    )
    return null
  }

  const question = screen.chatQuestions[questionIdx]
  const isLastQuestion = questionIdx === screen.chatQuestions?.length - 1

  const messagesToRender = useMemo(() => {
    return [...messages, ...messagesBuffer]
  }, [messages, messagesBuffer])

  const pushMessages = (newMessages: MessageData[]) => {
    updateChatData({
      messages: messages.concat(newMessages),
    })
  }

  const pushMessageToBuffer = (message: MessageData) => {
    setMessagesBuffer((messages) => messages.concat(message))
  }

  const incQuestion = () => {
    let nextIdx = questionIdx + 1

    if (isLastQuestion) {
      nextIdx = 0
      setIsEndReached(true)
      window.setTimeout(goNext, 1000)
    }

    updateChatData({
      questionIdx: nextIdx,
    })
  }

  const incQuestionWithTyping = () => {
    setIsTyping(true)

    timeoutRef.current = window.setTimeout(
      () => {
        setIsTyping(false)
        incQuestion()
      },
      500 + Math.round(Math.random() * 1500),
    )
  }

  const handleGenderSelect = (gender: Gender, text?: string) => {
    updateChatData({
      gender,
    })
    localStorage.setItem("gender", gender)
    handleSelect(text)
  }

  const handleSexualitySelect = (text?: string) => {
    if (text) {
      const sexuality = text.split(".").pop() as Sexuality

      updateChatData({
        sexuality,
      })
      localStorage.setItem("sexuality", sexuality)

      handleSelect(text)
    }
  }

  const handleSelect = (text?: string) => {
    pushMessages([...messagesBuffer, { text, isMine: true }])

    sendAmplitudeEvent("question_completed", {
      onboarding: question.id,
      question_text: intl.formatMessage({ id: question.label }),
      question_index: messages.filter(({ isMine }) => isMine).length,
      reply: intl.formatMessage({ id: text }),
    })

    setMessagesBuffer([])

    if (!isLastQuestion) {
      incQuestionWithTyping()
    } else {
      incQuestion()
    }
  }

  useEffect(() => {
    if (!question || isEndReached) {
      return
    }

    sendAmplitudeEvent("question_presented", {
      onboarding: question.id,
      question_text: intl.formatMessage({ id: question.label }),
      question_index: messages.filter(({ isMine }) => isMine).length,
    })

    switch (question.type) {
      case "image":
        const imageGender = FEMALE_PHOTO_SEXUALITIES_MAP[gender].includes(
          sexuality,
        )
          ? "f"
          : "m"
        const images = question.srcsByGender[imageGender]
        const src = images[Math.round(Math.random() * (images.length - 1))]

        pushMessageToBuffer({ src })
        pushMessageToBuffer({ text: question.label })
        break
      case "empty":
        pushMessages([{ text: question.label }])

        if (question.isTyping) {
          incQuestionWithTyping()
        } else {
          incQuestion()
        }

        break
      default:
        pushMessageToBuffer({ text: question.label })
    }
  }, [questionIdx])

  useEffect(() => {
    if (transitionStatus === "entered" || transitionStatus === "entering") {
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo(
            0,
            scrollContainerRef.current.scrollHeight,
          )
        }
      })
    }
  }, [question, messages, messagesBuffer, transitionStatus])

  const renderMessages = () => (
    <>
      {messagesToRender.map((message, idx) => {
        if (message.text) {
          return (
            <TextMessage
              key={message.text + idx}
              messageId={message.text}
              isMine={message.isMine}
            />
          )
        }

        if (message.src) {
          return (
            <ImageMessage
              key={message.src}
              src={message.src}
              isMine={message.isMine}
            />
          )
        }

        return null
      })}
    </>
  )

  const renderTyping = () => {
    return isTyping && <TypingMessage />
  }

  const renderQuestion = () => {
    if (!question || isEndReached || isTyping) {
      return null
    }

    switch (question.type) {
      case "empty":
        return null
      case "gender":
        return (
          <GenderQuestion
            options={question.options}
            handleSelect={handleGenderSelect}
          />
        )

        return null
      case "image":
        return (
          <SingleQuestion
            options={question.options}
            handleSelect={handleSelect}
          />
        )
      case "single":
        return (
          <SingleQuestion
            options={question.options}
            handleSelect={handleSelect}
          />
        )

        return null
      case "sexuality":
        const options = question.optionsByGender[gender]

        return (
          <SingleQuestion
            options={options}
            handleSelect={handleSexualitySelect}
          />
        )

        return null
      case "zodiak":
        return <ZodiakQuestion handleSelect={handleSelect} />
      default:
        return null
    }
  }

  return (
    <div
      className="absolute flex flex-col top-0 left-0 w-full min-h-full max-h-full overflow-auto bg-chat bg-cover bg-center"
      ref={scrollContainerRef}
    >
      <Header />
      <div className="flex flex-col justify-end grow px-4 pb-5">
        {renderMessages()}
        {renderTyping()}
        {renderQuestion()}
      </div>
    </div>
  )
}
