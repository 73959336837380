import { useEffect } from "react"
import qs from "query-string"
import { ReactComponent as Spinner } from "assets/images/spinner-s.svg"

export const OAuth = () => {
  let search = qs.parse(window.location.search)

  if (!Object.keys(search).length) {
    search = qs.parse(window.location.hash)
  }

  useEffect(() => {
    if (window.opener?.onOAuthSuccess) {
      window.opener.onOAuthSuccess(search)
    } else {
      localStorage.setItem("fb_auth_search", JSON.stringify(search))
    }

    window.close()
  }, [])
  return (
    <div className="w-full h-full flex justify-center items-center">
      <Spinner className="animate-spin" />
    </div>
  )
}
