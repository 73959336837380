import { useEffect, useState } from "react"
import Lottie from "lottie-react"
import { Button, QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

import LikePng from "assets/images/like.png"
import DislikePng from "assets/images/dislike.png"
import Profile1Png from "assets/images/questions/profile-1.png"
import Profile2Png from "assets/images/questions/profile-2.png"
import Profile3Png from "assets/images/questions/profile-3.png"
import Profile4Png from "assets/images/questions/profile-4.png"
import LikeJson from "assets/animations/like.json"
import DislikeJson from "assets/animations/dislike.json"

const PHOTOS = [
  { id: 1, img: Profile1Png },
  { id: 2, img: Profile2Png },
  { id: 3, img: Profile3Png },
  { id: 4, img: Profile4Png },
]

type LikeQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string) => void
}

export function LikeQuestion(props: LikeQuestionProps) {
  const {
    screen: { text },
    onClick,
  } = props
  const [photo, setPhoto] = useState<string>()
  const [animationData, setAnimationData] = useState<object>()
  const [answer, setAnswer] = useState<string | undefined>()

  const getRandomPhoto = () => {
    let usedPhotos: number[] = JSON.parse(
      localStorage.getItem("usedPhotos") || "[]",
    )
    const filtered = PHOTOS.filter((item) => !usedPhotos.includes(item.id))
    if (filtered.length === 1) {
      const { img, id } = filtered[0]
      setPhoto(img)
      usedPhotos = [id]
    } else {
      const index = Math.floor(Math.random() * filtered.length)
      const { img, id } = filtered[index]
      setPhoto(img)
      usedPhotos.push(id)
    }
    localStorage.setItem("usedPhotos", JSON.stringify(usedPhotos))
  }

  useEffect(() => {
    getRandomPhoto()
  }, [])

  return (
    <div className="flex flex-col justify-between items-center h-full">
      <QuestionTitle id={text} />
      <div className="relative flex justify-center mb-4 w-full">
        <img className="w-[218px] height-575:w-[190px]" src={photo} />
        {animationData && (
          <Lottie
            className="absolute top-0"
            animationData={animationData}
            loop={false}
            onComplete={() => onClick(props.screen.id, answer)}
          />
        )}
      </div>
      <div className="flex gap-5 pb-4">
        <Button
          key="dislike"
          onClick={() => {
            setAnimationData(DislikeJson)
            setAnswer("dislike")
          }}
          disabled={!!animationData}
        >
          <div className="w-28 h-28 flex justify-center items-center height-575:w-24 height-575:h-24">
            <img
              className="h-16 w-16 height-575:w-14 height-575:h-14"
              src={DislikePng}
            />
          </div>
        </Button>
        <Button
          key="like"
          onClick={() => {
            setAnimationData(LikeJson)
            setAnswer("like")
          }}
          disabled={!!animationData}
        >
          <div className="w-28 h-28 flex justify-center items-center height-575:w-24 height-575:h-24">
            <img
              className="h-16 w-16 height-575:w-14 height-575:h-14"
              src={LikePng}
            />
          </div>
        </Button>
      </div>
    </div>
  )
}
