import maleImg1 from "../../../assets/images/profile-map-marker-1-m.png"
import maleImg2 from "../../../assets/images/profile-map-marker-2-m.png"
import maleImg3 from "../../../assets/images/profile-map-marker-3-m.png"
import maleImg4 from "../../../assets/images/profile-map-marker-4-m.png"
import maleImg5 from "../../../assets/images/profile-map-marker-5-m.png"
import femaleImg1 from "../../../assets/images/profile-map-marker-1-f.png"
import femaleImg2 from "../../../assets/images/profile-map-marker-2-f.png"
import femaleImg3 from "../../../assets/images/profile-map-marker-3-f.png"
import femaleImg4 from "../../../assets/images/profile-map-marker-4-f.png"
import femaleImg5 from "../../../assets/images/profile-map-marker-5-f.png"
import femaleImg6 from "../../../assets/images/profile-map-marker-6-f.png"
import femaleImg7 from "../../../assets/images/profile-map-marker-7-f.png"
import femaleImg8 from "../../../assets/images/profile-map-marker-8-f.png"
import femaleImg9 from "../../../assets/images/profile-map-marker-9-f.png"
import femaleImg10 from "../../../assets/images/profile-map-marker-10-f.png"

export const MARKERS = [
  {
    src: maleImg1,
    age: 21,
  },
  {
    src: maleImg2,
    age: 36,
  },
  {
    src: maleImg3,
    age: 30,
  },
  {
    src: maleImg4,
    age: 28,
  },
  {
    src: maleImg5,
    age: 25,
  },
  {
    src: femaleImg1,
    age: 33,
  },
  {
    src: femaleImg2,
    age: 30,
  },
  {
    src: femaleImg3,
    age: 21,
  },
  {
    src: femaleImg4,
    age: 25,
  },
  {
    src: femaleImg5,
    age: 26,
  },
  {
    src: femaleImg6,
    age: 21,
  },
  {
    src: femaleImg7,
    age: 29,
  },
  {
    src: femaleImg8,
    age: 27,
  },
  {
    src: femaleImg9,
    age: 25,
  },
  {
    src: femaleImg10,
    age: 24,
  },
]
