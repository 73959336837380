export const generateAppsFlyerLink = (surveyId: string) => {
  const baseUrl = "https://pureapp.onelink.me/M5p9"
  const searchParams = new URL(location.href).searchParams
  const destinationUrl = new URL(baseUrl)

  const source =
    searchParams.get("pid") || searchParams.get("utm_source") || "Webfunnel"
  const campaign =
    searchParams.get("c") || searchParams.get("utm_campaign") || surveyId
  const adset = searchParams.get("af_adset")
  const adname = searchParams.get("af_ad")
  const ScCid = searchParams.get("ScCid")
  const gclidUrl = searchParams.get("gclid")

  const cookies = document.cookie
    .split("; ")
    .map((element) => element.split("="))
    .reduce<Record<string, string>>((acc, curr) => {
      acc[curr[0]] = curr.slice(1).join("=")
      return acc
    }, {})
  const fbp = cookies["_fbp"]
  const fbc = cookies["_fbc"]
  const gclid = cookies["gclid"]
  const gbraid = cookies["gbraid"]
  const wbraid = cookies["wbraid"]

  destinationUrl.searchParams.append("af_xp", "custom")
  destinationUrl.searchParams.append("pid", source)
  destinationUrl.searchParams.append("c", campaign)

  if (adset) {
    destinationUrl.searchParams.append("af_adset", adset)
  }

  if (adname) {
    destinationUrl.searchParams.append("af_ad", adname)
  }

  if (source.toLowerCase().includes("fb")) {
    if (fbp) {
      destinationUrl.searchParams.append("af_sub1", fbp)
    }
    if (fbc) {
      destinationUrl.searchParams.append("af_sub2", fbc)
    }
  } else if (source.toLowerCase().includes("googleads")) {
    if (gbraid) {
      destinationUrl.searchParams.append("af_sub1", gbraid)
    }

    if (wbraid) {
      destinationUrl.searchParams.append("af_sub2", wbraid)
    }

    if (gclid || gclidUrl) {
      const value = gclid || gclidUrl || ""
      destinationUrl.searchParams.append("af_sub3", value)
    }
  } else if (source.toLowerCase().includes("snapchat")) {
    if (ScCid) {
      destinationUrl.searchParams.append("af_sub1", ScCid)
    }
  }

  return destinationUrl.href
}
