import type { ComponentPropsWithoutRef } from "react"
import { twMerge } from "tailwind-merge"
import { FormattedMessage } from "react-intl"
import { ReactComponent as ButtonSvg } from "assets/images/start-button.svg"

import { ReactComponent as BackgroundSvg } from "assets/images/chat/select-button-bg.svg"

export type SpaceButtonProps = {
  message?: string
} & ComponentPropsWithoutRef<"button">

export function SpaceButton({
  message,
  className,
  ...otherProps
}: SpaceButtonProps) {
  return (
    <button
      {...otherProps}
      className={twMerge(
        "relative text-pink hover:text-white transition-colors",
        className,
      )}
    >
      <ButtonSvg />
      <div className="flex justify-center items-center h-14 w-full absolute top-0 text-black text-2xl uppercase tracking-widest font-sans-bold">
        <FormattedMessage id={message} />
      </div>
    </button>
  )
}
