import type { ComponentPropsWithoutRef, ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { FormattedMessage } from "react-intl"

import type { Gender } from "config/types"
import { ReactComponent as BackgroundSvg } from "assets/images/chat/gender-button-bg.svg"
import MaleImage from "assets/images/chat/male-gender.png"
import FemaleImage from "assets/images/chat/female-gender.png"

export type ChatGenderButtonProps = {
  gender: Gender
  chilren?: ReactNode
} & ComponentPropsWithoutRef<"button">

export function ChatGenderButton({
  gender,
  className,
  children,
  ...otherProps
}: ChatGenderButtonProps) {
  const isMale = gender === "m"

  return (
    <button
      {...otherProps}
      className={twMerge(
        "flex items-center justify-between relative min-w-48 pl-4 pr-1 py-3 text-white hover:text-violet active:text-violet transition-all",
        className,
      )}
      type="button"
    >
      <BackgroundSvg className="absolute top-0 left-0 w-full h-full" />
      <span className="relative text-[18px] leading-[22px] text-black">
        {children}
      </span>
      <img
        className="relative"
        src={isMale ? MaleImage : FemaleImage}
        width="48px"
        height="48px"
      />
    </button>
  )
}
