import type { Screen } from "./types"

import picture1829 from "assets/images/v43/18-29.webp"
import picture3039 from "assets/images/v43/30-39.webp"
import picture4049 from "assets/images/v43/40-49.webp"
import picture50plus from "assets/images/v43/50+.webp"

import pictureReview from "assets/images/v43/review.webp"
import pictureFeatured from "assets/images/v43/featured-in.webp"

import pictureGender from "assets/images/questions/gender.png"

import pictureLookingFor0 from "assets/images/v43/exploring-options.webp"
import pictureLookingFor1 from "assets/images/v43/casual-dates.webp"
import pictureLookingFor2 from "assets/images/v43/serious-relationship.webp"
import pictureLookingFor3 from "assets/images/v43/just-chatting.webp"
// import pictureLookingFor4 from "assets/images/v43/fun-and-hookups.webp"

import pictureBinoculars from "assets/images/v43/binoculars.webp"
import pictureMeetNewPeople from "assets/images/questions/turnons2.png"
import pictureSetYourVision from "assets/images/v43/set-your-vision.webp"
import pictureEducation from "assets/images/questions/languages.png"
import pictureFitness from "assets/images/questions/partner-qualities.png"
import pictureAnalysis from "assets/images/v43/analysis.webp"
import pictureCharacterTrait from "assets/images/questions/age-2.png"
import pictureFirstDate from "assets/images/questions/first-date.png"

import pictureIdealDate0 from "assets/images/v43/coffee-or-a-meal.webp"
import pictureIdealDate1 from "assets/images/v43/event-or-concert.webp"
import pictureIdealDate2 from "assets/images/v43/walk-or-hike.webp"
import pictureIdealDate3 from "assets/images/v43/museum.webp"

import pictureDevilDance from "assets/images/v43/devil-dance.webp"
import pictureIntimateMoments from "assets/images/questions/turnons-bedroom.png"

import pictureMassage0 from "assets/images/v43/give-massage.webp"
import pictureMassage1 from "assets/images/v43/receive-massage.webp"

import pictureMoments0 from "assets/images/v43/listen-music.webp"
import pictureMoments1 from "assets/images/v43/dirty-talk.webp"

import pictureRoleplay0 from "assets/images/v43/roleplay-yes.webp"
import pictureRoleplay1 from "assets/images/v43/roleplay-no.webp"

import pictureTheme from "assets/images/questions/open-relationship.png"
import pictureRedFlags from "assets/images/questions/turnons3.png"

import picturePublicPrivate0 from "assets/images/v43/public-place.webp"
import picturePublicPrivate1 from "assets/images/v43/private.webp"

import pictureParty0 from "assets/images/v43/strip-club.webp"
import pictureParty1 from "assets/images/v43/kinky-party.webp"

import pictureToys0 from "assets/images/v43/butt-plug.webp"
import pictureToys1 from "assets/images/v43/dildo.webp"

import pictureWeHelped from "assets/images/v43/weve-helped.webp"

export const SURVEY_CONFIG_V43: Array<Screen[]> = [
  [
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.first_screen.title",
      subtitle: "v43.first_screen.subtitle",
      caption: "v43.first_screen.caption",
      buttonTheme: "darkgray",
      id: "screen_age",
      pictures: [
        {
          value: "18-29",
          picture: picture1829,
        },
        {
          value: "30-39",
          picture: picture3039,
        },
        {
          value: "40-49",
          picture: picture4049,
        },
        {
          value: "50+",
          picture: picture50plus,
        },
      ],
    },
    {
      type: "review_stub",
      bgColor: "#bcaef7",
      textColor: "#000000",
      progressBarTheme: "white",
      logoColor: "transparent",
      title: "v43.review_stub.title",
      image: pictureReview,
      featuredImage: pictureFeatured,
      id: "screen_review_stub",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.current_relationship.text",
      image: pictureGender,
      options: [
        "v43.current_relationship.0",
        "v43.current_relationship.1",
        "v43.current_relationship.2",
        "v43.current_relationship.3",
      ],
      id: "screen_current_relationships",
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.looking_for.text",
      buttonTheme: "darkgray",
      id: "screen_looking_for",
      pictures: [
        {
          value: "v43.looking_for.0",
          picture: pictureLookingFor0,
        },
        {
          value: "v43.looking_for.1",
          picture: pictureLookingFor1,
        },
        {
          value: "v43.looking_for.2",
          picture: pictureLookingFor2,
        },
        {
          value: "v43.looking_for.3",
          picture: pictureLookingFor3,
        },
        // {
        //   value: "v43.looking_for.4",
        //   picture: pictureLookingFor4,
        // },
      ],
    },
    {
      type: "stub",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "violet",
      title: "v43.personalized_dating.title",
      text: "v43.personalized_dating.text",
      image: pictureBinoculars,
      id: "screen_personalized_dating",
    },
    {
      type: "multiple",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "violet",
      selectTheme: "gray",
      text: "v43.people_type.text",
      options: [
        "v43.people_type.0",
        "v43.people_type.1",
        "v43.people_type.2",
        "v43.people_type.3",
        "v43.people_type.4",
        "v43.people_type.5",
        "v43.people_type.6",
        "v43.people_type.7",
      ],
      id: "screen_people_type",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.meet_new_people.text",
      image: pictureMeetNewPeople,
      options: [
        "v43.meet_new_people.0",
        "v43.meet_new_people.1",
        "v43.meet_new_people.2",
        "v43.meet_new_people.3",
        "v43.meet_new_people.4",
        "v43.meet_new_people.5",
      ],
      id: "screen_meet_new_people",
    },
    {
      type: "stub",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "violet",
      title: "v43.set_your_vision.title",
      text: "v43.set_your_vision.text",
      image: pictureSetYourVision,
      id: "screen_set_your_vision",
    },
  ],
  [
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.educational_background.text",
      image: pictureEducation,
      options: [
        "v43.educational_background.0",
        "v43.educational_background.1",
        "v43.educational_background.2",
      ],
      id: "screen_educational_background",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.physical_fitness.text",
      image: pictureFitness,
      options: [
        "v43.physical_fitness.0",
        "v43.physical_fitness.1",
        "v43.physical_fitness.2",
      ],
      id: "screen_physical_fitness",
    },
    {
      type: "analyze",
      image: pictureAnalysis,
      logoColor: "transparent",
      progressBarTheme: "white",
      options: ["v2.analyze.1", "v2.analyze.2", "v2.analyze.3", "v2.analyze.4"],
      id: "screen_analyzing",
      bgColor: "#BBADF7",
      textColor: "#000000",
      buttonTheme: "darkgray",
      text: "v2.analyze.text",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.character_trait.text",
      image: pictureCharacterTrait,
      options: [
        "v43.character_trait.0",
        "v43.character_trait.1",
        "v43.character_trait.2",
        "v43.character_trait.3",
        "v43.character_trait.4",
      ],
      id: "screen_character_trait",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.how_soon_to_meet.text",
      image: pictureFirstDate,
      options: [
        "v43.how_soon_to_meet.0",
        "v43.how_soon_to_meet.1",
        "v43.how_soon_to_meet.2",
        "v43.how_soon_to_meet.3",
      ],
      id: "screen_how_soon_to_meet",
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.first_date.text",
      buttonTheme: "darkgray",
      id: "screen_first_date",
      pictures: [
        {
          value: "v43.first_date.0",
          picture: pictureIdealDate0,
        },
        {
          value: "v43.first_date.1",
          picture: pictureIdealDate1,
        },
        {
          value: "v43.first_date.2",
          picture: pictureIdealDate2,
        },
        {
          value: "v43.first_date.3",
          picture: pictureIdealDate3,
        },
      ],
    },
    {
      type: "stub",
      logoColor: "transparent",
      progressBarTheme: "white",
      bgColor: "#BBADF7",
      textColor: "#000000",
      buttonTheme: "black",
      title: "v43.sexual_desires.title",
      text: "v43.sexual_desires.text",
      image: pictureDevilDance,
      id: "screen_sexual_desires",
    },
  ],
  [
    {
      type: "zodiak",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "zodiak.question",
      id: "screen_zodiac",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.intimate_moments.text",
      image: pictureIntimateMoments,
      options: [
        "v43.intimate_moments.0",
        "v43.intimate_moments.1",
        "v43.intimate_moments.2",
        "v43.intimate_moments.3",
        "v43.intimate_moments.4",
      ],
      id: "screen_intimate_moments",
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.sensual_massage.text",
      buttonTheme: "darkgray",
      id: "screen_sensual_massage",
      pictures: [
        {
          value: "v43.sensual_massage.0",
          picture: pictureMassage0,
        },
        {
          value: "v43.sensual_massage.1",
          picture: pictureMassage1,
        },
      ],
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.during_moments.text",
      buttonTheme: "darkgray",
      id: "screen_during_moments",
      pictures: [
        {
          value: "v43.during_moments.0",
          picture: pictureMoments0,
        },
        {
          value: "v43.during_moments.1",
          picture: pictureMoments1,
        },
      ],
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.roleplay.text",
      buttonTheme: "darkgray",
      id: "screen_roleplay",
      pictures: [
        {
          value: "v43.roleplay.0",
          picture: pictureRoleplay0,
        },
        {
          value: "v43.roleplay.1",
          picture: pictureRoleplay1,
        },
      ],
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.theme.text",
      image: pictureTheme,
      imageSmaller: true,
      options: ["v43.theme.0", "v43.theme.1", "v43.theme.2", "v43.theme.3"],
      id: "screen_theme",
    },
    {
      type: "single",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      buttonTheme: "darkgray",
      text: "v43.red_flags.text",
      image: pictureRedFlags,
      imageSmaller: true,
      options: [
        "v43.red_flags.0",
        "v43.red_flags.1",
        "v43.red_flags.2",
        "v43.red_flags.3",
      ],
      id: "screen_red_flags",
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.public_or_private.text",
      buttonTheme: "darkgray",
      id: "screen_public_or_private",
      pictures: [
        {
          value: "v43.public_or_private.0",
          picture: picturePublicPrivate0,
        },
        {
          value: "v43.public_or_private.1",
          picture: picturePublicPrivate1,
        },
      ],
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.party.text",
      buttonTheme: "darkgray",
      id: "screen_party",
      pictures: [
        {
          value: "v43.party.0",
          picture: pictureParty0,
        },
        {
          value: "v43.party.1",
          picture: pictureParty1,
        },
      ],
    },
    {
      type: "single_with_pictures",
      logoColor: "transparent",
      bgColor: "#292929",
      textColor: "#ffffff",
      title: "v43.toys.text",
      buttonTheme: "darkgray",
      id: "screen_toys",
      pictures: [
        {
          value: "v43.toys.0",
          picture: pictureToys0,
        },
        {
          value: "v43.toys.1",
          picture: pictureToys1,
        },
      ],
    },
    {
      type: "review_stub",
      bgColor: "#bcaef7",
      textColor: "#000000",
      progressBarTheme: "white",
      logoColor: "transparent",
      image: pictureAnalysis,
      featuredImage: pictureWeHelped,
      id: "screen_help_you",
    },
    {
      type: "auth",
      textColor: "#000000",
      showFacebook: true,
      logoColor: "transparent",
      action: "registration",
      id: "screen_registration",
    },
    {
      type: "paygate",
      paygateType: "paymentButtonsTop",
      withEverflow: true,
      hideProgressBar: true,
      noMaxHeight: true,
      noMaxWidth: true,
      action: "checkout",
      id: "screen_paywall",
      bgColor: "#1A1717",
      bundles: {
        year: {
          us: {
            name: {
              paypal: "pure-web.year.paypal.us",
              cardpay: "pure-web.year.cardpay.us",
            },
            label: "paygate.subscription.year",
          },
          uk: {
            name: {
              paypal: "pure-web.year.paypal.uk",
              cardpay: "pure-web.year.cardpay.uk",
            },
            label: "paygate.subscription.year",
          },
          eu: {
            name: {
              paypal: "pure-web.year.paypal.eu",
              cardpay: "pure-web.year.cardpay.eu",
            },
            label: "paygate.subscription.year",
          },
          row: {
            name: {
              paypal: "pure-web.year.paypal.row",
              cardpay: "pure-web.year.cardpay.row",
            },
            label: "paygate.subscription.year",
          },
        },
        month: {
          us: {
            name: {
              paypal: "pure-web.monthly.paypal.us",
              cardpay: "pure-web.monthly.cardpay.us",
            },
            label: "paygate.subscription.month",
          },
          uk: {
            name: {
              paypal: "pure-web.monthly.paypal.uk",
              cardpay: "pure-web.monthly.cardpay.uk",
            },
            label: "paygate.subscription.month",
          },
          eu: {
            name: {
              paypal: "pure-web.monthly.paypal.eu",
              cardpay: "pure-web.monthly.cardpay.eu",
            },
            label: "paygate.subscription.month",
          },
          row: {
            name: {
              paypal: "pure-web.monthly.paypal.row",
              cardpay: "pure-web.monthly.cardpay.row",
            },
            label: "paygate.subscription.month",
          },
        },
        week: {
          us: {
            name: {
              paypal: "pure-web.weekly.paypal.us",
              cardpay: "pure-web.weekly.cardpay.us",
            },
            label: "paygate.subscription.week",
          },
          uk: {
            name: {
              paypal: "pure-web.weekly.paypal.uk",
              cardpay: "pure-web.weekly.cardpay.uk",
            },
            label: "paygate.subscription.week",
          },
          eu: {
            name: {
              paypal: "pure-web.weekly.paypal.eu",
              cardpay: "pure-web.weekly.cardpay.eu",
            },
            label: "paygate.subscription.week",
          },
          row: {
            name: {
              paypal: "pure-web.weekly.paypal.row",
              cardpay: "pure-web.weekly.cardpay.row",
            },
            label: "paygate.subscription.week",
          },
        },
      },
    },
    {
      type: "payment-success",
      hideProgressBar: true,
      action: "purchase_success",
      noMaxWidth: true,
    },
  ],
]
