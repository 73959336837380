import type { Gender } from "config/types"
import type { ChatData } from "components/organisms/survey"

export const LOCAL_STORAGE_FIELDS = {
  stepsCompleted: "steps_completed",
  lastAnswerTimestamp: "last_answer_timestamp",
  chatHistory: "chat_history",
}

const CACHE_TTL_SECONDS = 86400

export function saveCompletedSteps(surveyId: string, step: number) {
  const currentValue = localStorage.getItem(LOCAL_STORAGE_FIELDS.stepsCompleted)

  if (currentValue) {
    try {
      localStorage.setItem(
        LOCAL_STORAGE_FIELDS.stepsCompleted,
        JSON.stringify({
          ...JSON.parse(currentValue),
          [surveyId]: step,
        }),
      )
    } catch {
      localStorage.setItem(
        LOCAL_STORAGE_FIELDS.stepsCompleted,
        JSON.stringify({
          [surveyId]: step,
        }),
      )
    }
  } else {
    localStorage.setItem(
      LOCAL_STORAGE_FIELDS.stepsCompleted,
      JSON.stringify({
        [surveyId]: step,
      }),
    )
  }

  localStorage.setItem(
    LOCAL_STORAGE_FIELDS.lastAnswerTimestamp,
    Date.now().toString(),
  )
}

export function resetCompletedSteps(surveyId: string) {
  const currentValue = localStorage.getItem(LOCAL_STORAGE_FIELDS.stepsCompleted)

  if (currentValue) {
    try {
      const { [surveyId]: _, ...newValue } = JSON.parse(currentValue)
      localStorage.setItem(
        LOCAL_STORAGE_FIELDS.stepsCompleted,
        JSON.stringify(newValue),
      )
    } catch {
      localStorage.removeItem(LOCAL_STORAGE_FIELDS.stepsCompleted)
    }
  }
}

export function getCompletedStepId(surveyId: string) {
  const lastAnswerTimestamp = localStorage.getItem(
    LOCAL_STORAGE_FIELDS.lastAnswerTimestamp,
  )

  if (lastAnswerTimestamp && !isNaN(Number(lastAnswerTimestamp))) {
    if ((Date.now() - Number(lastAnswerTimestamp)) / 1000 > CACHE_TTL_SECONDS) {
      localStorage.removeItem(LOCAL_STORAGE_FIELDS.stepsCompleted)
      localStorage.removeItem(LOCAL_STORAGE_FIELDS.lastAnswerTimestamp)
      return 0
    }
  }

  try {
    return (
      JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_FIELDS.stepsCompleted) || "",
      )[surveyId] || 0
    )
  } catch {
    return 0
  }
}

export function saveChatData(chatId: string, data: ChatData) {
  const json = JSON.stringify({
    ...data,
    timestamp: Date.now(),
  })

  localStorage.setItem(`${chatId}:${LOCAL_STORAGE_FIELDS.chatHistory}`, json)
}

export function readChatData<T>(chatId: string) {
  try {
    const data = JSON.parse(
      localStorage.getItem(`${chatId}:${LOCAL_STORAGE_FIELDS.chatHistory}`) ||
        "",
    )

    if (data.timestamp <= Date.now() + CACHE_TTL_SECONDS) {
      return {
        messages: data.messages as T[],
        gender: data.gender || "m",
        sexuality: data.sexuality || "h",
        questionIdx: data.questionIdx || 0,
      }
    } else {
      throw new Error("Cache expired")
    }
  } catch (err) {
    return {
      messages: [],
      gender: "m",
      sexuality: "h",
      questionIdx: 0,
    }
  }
}

export function resetChatData(chatId: string) {
  localStorage.removeItem(`${chatId}:${LOCAL_STORAGE_FIELDS.chatHistory}`)
}

export {}
