import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { PrimaryButton } from "components/molecules"
import { Checkbox, QuestionTitle } from "components/atoms"

import kissesPng from "assets/images/questions/kisses.png"
import bitingPng from "assets/images/questions/biting.png"
import dirtytalkPng from "assets/images/questions/dirtytalk.png"
import edgingPng from "assets/images/questions/edging.png"
import scentsPng from "assets/images/questions/scents.png"
import footfetishPng from "assets/images/questions/footfetish.png"
import { ReactComponent as BorderImg } from "assets/images/turnons-border.svg"

import type { Screen } from "config/types"
import { twMerge } from "tailwind-merge"

type TurnonsQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string[]) => void
}

const TURNONS = [
  { key: "kisses", img: kissesPng },
  { key: "biting", img: bitingPng },
  { key: "dirtytalk", img: dirtytalkPng },
  { key: "edging", img: edgingPng },
  { key: "scents", img: scentsPng },
  { key: "footfetish", img: footfetishPng },
]

export function TurnonsQuestion(props: TurnonsQuestionProps) {
  const {
    screen: { text, id },
    onClick,
  } = props

  const [selected, setSelected] = useState<string[]>([])

  const handleClick = (value: string) => {
    const set = new Set(selected)
    if (set.has(value)) {
      set.delete(value)
    } else {
      set.add(value)
    }
    setSelected(Array.from(set))
  }

  return (
    <div className="flex flex-col h-full">
      <QuestionTitle id={text} />
      <div className="flex items-center grow">
        <div className="flex flex-wrap gap-y-1 justify-center">
          {TURNONS.map(({ key, img }) => (
            <div
              key={key}
              className="w-[112px] h-[112px] flex flex-col items-center justify-center relative m-2 height-575:my-0"
              onClick={() => handleClick(key)}
            >
              <BorderImg
                className={twMerge(
                  "absolute w-full h-full",
                  selected.includes(key) ? "text-gold" : "text-gray-50",
                )}
              />
              <img
                src={img}
                className="cursor-pointer h-[60px] z-10"
                onClick={() => handleClick(key)}
              />
              <div className="font-serif-italic text-sm z-10">
                <FormattedMessage id={`turnons.${key}`} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full text-center px-7 pt-3 pb-4">
        <PrimaryButton onClick={() => onClick(id, selected)}>
          <FormattedMessage id="continue" />
        </PrimaryButton>
      </div>
    </div>
  )
}
