import { FormattedMessage, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"
import { PrimaryButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

type SingleChoiceQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string) => void
}

export function SingleChoicePicturesQuestion(props: SingleChoiceQuestionProps) {
  const {
    screen: { title, subtitle, caption, buttonTheme, pictures = [] },
    onClick,
  } = props
  const intl = useIntl()

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <QuestionTitle
          id={title}
          className={twMerge(
            pictures.length === 2 && "min-h-[120px]",
            pictures.length === 4 && "height-730:!text-md",
          )}
        />
        {subtitle && (
          <QuestionTitle
            className={twMerge(
              "!pt-0 text-md",
              pictures.length === 4 && "height-730:!text-sm",
            )}
            id={subtitle}
          />
        )}
        {caption && (
          <div className="text-center text-pink text-xs mb-2 height-770:text-[11px]">
            <FormattedMessage id={caption} defaultMessage={caption} />
          </div>
        )}
      </div>
      <div
        className={twMerge(
          "flex items-center gap-y-1 pb-4 height-575:gap-y-0",
          "flex-wrap justify-center",
          pictures.length === 2 && "grow",
        )}
      >
        {pictures.map(({ value, picture }) => (
          <PrimaryButton
            theme={buttonTheme}
            className={twMerge(
              "w-[calc(50%-10px)] odd:mr-2.5 even:ml-2.5 last:!mr-0",
              !!(subtitle && caption) &&
                "height-840:w-[calc(40%-10px)] height-575:w-[calc(35%-10px)]",
            )}
            key={value}
            onClick={() =>
              onClick(
                props.screen.id,
                intl.formatMessage({ id: value, defaultMessage: value }),
              )
            }
          >
            <img src={picture} />
            <div
              className={twMerge(
                "height-575:text-xs",
                "h-[36px] flex items-center justify-center mt-1",
                pictures.length === 2 && "text-xs",
                pictures.some(
                  ({ value }) =>
                    intl.formatMessage({ id: value, defaultMessage: value })
                      .length > 36,
                ) && "h-[46px]",
              )}
            >
              <FormattedMessage id={value} defaultMessage={value} />
            </div>
          </PrimaryButton>
        ))}
      </div>
    </div>
  )
}
