const isSnapchatActive = () => {
  return Boolean(
    !window.location.origin.includes("test") &&
      !window.location.pathname.includes("oauth") &&
      !window.location.pathname.includes("payment-") &&
      window.location.origin.includes("anythingbutboring") &&
      //@ts-ignore
      window.snaptr,
  )
}

export const trackSnapchatClick = () => {
  if (isSnapchatActive()) {
    //@ts-ignore
    window.snaptr("track", "ADD_CART")
  }
}

export const trackSnapchatSignup = (method: string) => {
  if (isSnapchatActive() && !sessionStorage.getItem("sc_signup_tracked")) {
    //@ts-ignore
    window.snaptr("track", "SIGN_UP", {
      sign_up_method: method,
    })
    sessionStorage.setItem("sc_signup_tracked", "1")
  }
}

export const trackSnapchatPurchaseClick = (
  price: number,
  currency: string,
  bundleName: string,
) => {
  if (
    isSnapchatActive() &&
    !sessionStorage.getItem("sc_start_checkout_tracked")
  ) {
    //@ts-ignore
    window.snaptr("track", "START_CHECKOUT", {
      price,
      currency,
      item_ids: [bundleName],
    })
    sessionStorage.setItem("sc_start_checkout_tracked", "1")
  }
}

export const trackSnapchatPurchaseSuccess = (
  price: number,
  currency: string,
  bundleName: string,
) => {
  if (isSnapchatActive() && !sessionStorage.getItem("sc_purchase_tracked")) {
    //@ts-ignore
    window.snaptr("track", "PURCHASE", {
      price,
      currency,
      item_ids: [bundleName],
    })
    sessionStorage.setItem("sc_purchase_tracked", "1")
  }
}
