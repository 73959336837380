import { QuestionTitle } from "components/atoms"

import { ZodiakSigns } from "../molecules/zodiak-signs"
import type { Screen } from "config/types"

type ZodiakQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string) => void
}

export function ZodiakQuestion(props: ZodiakQuestionProps) {
  const {
    screen: { text, id, buttonTheme },
    onClick,
  } = props

  const handleSignClick = (reply?: string) => {
    onClick(id, reply)
  }

  return (
    <div className="flex flex-col h-full">
      <QuestionTitle id={text} />
      <ZodiakSigns
        className="pt-5"
        onClick={handleSignClick}
        buttonTheme={buttonTheme}
      />
    </div>
  )
}
