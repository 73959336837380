import type { ComponentPropsWithoutRef } from "react"
import { twMerge } from "tailwind-merge"
import { FormattedMessage } from "react-intl"

import { ReactComponent as BackgroundSvg } from "assets/images/chat/select-button-bg.svg"

export type CurveBorderedButtonProps = {} & ComponentPropsWithoutRef<"button">

export function CurveBorderedButton({
  children,
  className,
  ...otherProps
}: CurveBorderedButtonProps) {
  return (
    <button
      {...otherProps}
      className={twMerge(
        "box-border block items-center relative min-w-36 py-3 px-4 text-white hover:text-violet active:text-violet transition-all",
        className,
      )}
      type="button"
    >
      <BackgroundSvg className="absolute top-0 left-0 w-full h-full" />
      <span className="relative inline-block w-full text-[14px] leading-[18px] uppercase text-center text-black font-sans-bold">
        {children}
      </span>
    </button>
  )
}
