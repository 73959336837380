import { useEffect } from "react"

export function PaymentSuccessHandler() {
  useEffect(() => {
    if (window.opener?.onPurchaseSuccess) {
      window.opener?.onPurchaseSuccess()
    }
    window.close()
  }, [])

  return <></>
}
