import { FormattedMessage, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"

export function QuestionTitle({
  id,
  className,
}: {
  id?: string
  className?: string
}) {
  const intl = useIntl()
  return (
    <div
      className={twMerge(
        "uppercase flex justify-center items-center px-4 text-2xl tracking-[2px] text-center pt-6 pb-4 height-700:pt-4 height-650:!py-2 height-650:text-lg",
        intl.formatMessage({ id }).length > 62 && "text-lg height-575:!text-sm",
        className,
      )}
    >
      <FormattedMessage id={id} />
    </div>
  )
}
