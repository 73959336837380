import { PaymentRegion, Screen, SubscriptionDuration } from "config/types"
import { detectUserRegion, soul } from "lib/soul"
import { useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import { ReactComponent as ReviewsBorder } from "assets/images/reviews-border.svg"
import { ReactComponent as TermsDesktopBorder } from "assets/images/terms-bg.svg"
import paypalLogo from "assets/images/paypal.png"
import checkIcon from "assets/images/paygate-check.png"
import checkIconPink from "assets/images/paygate-check-pink.png"
import checkIconWhite from "assets/images/check-white.svg"
import starsIcon from "assets/images/5-stars.png"
import avatar1 from "assets/images/review-avatar-1.png"
import avatar2 from "assets/images/review-avatar-2.png"
import avatar3 from "assets/images/review-avatar-3.png"
import avatar4 from "assets/images/review-avatar-4.png"
import featuredImg from "assets/images/featured-in.png"
import featuredDesktopImg from "assets/images/featured-in-desktop.png"
import borderImg from "assets/images/paygate-top-border.svg"
import mastercardLogo from "assets/images/mastercard.png"
import visaLogo from "assets/images/visa.png"
import coverImage from "assets/images/orgy.png"
import coverVioletImg from "assets/images/orgy-violet.png"
import borderPaymentButtonsDesktop from "assets/images/payment-buttons-border-desktop.png"

import { ReactComponent as Spinner } from "assets/images/spinner-s.svg"
import { ReactComponent as BorderX } from "assets/images/border-x.svg"
import { ReactComponent as BorderY } from "assets/images/border-y.svg"
import { ReactComponent as BenefitsBg } from "assets/images/benefits-bg-desktop.svg"
import { ReactComponent as BenefitsTitleBubble } from "assets/images/benefits-title-bubble-desktop.svg"
import { ReactComponent as WhiteBottomDivider } from "assets/images/white-bottom-divider.svg"
import { sendAmplitudeEvent } from "lib/amplitude"
import { isSafari } from "lib/device"
import { trackMetaEvent } from "lib/meta"
import { sendEverflowConversion } from "lib/everflow"
import { sendGooglePurchaseConversion } from "lib/google"
import { PrimaryButton } from "components/molecules"
import { Logo } from "components/atoms"
import {
  trackSnapchatPurchaseClick,
  trackSnapchatPurchaseSuccess,
} from "lib/snapchat"

export type PaygateProps = {
  screen: Screen
  onSuccess: () => void
  surveyId: string
  onPaymentError: (alias?: string, code?: number) => void
  type?: "paymentButtonsTop" | "paymentButtonsBottom"
}

export type Currency = "USD" | "EUR" | "GBP"

export type ApiBundle = {
  currency: Currency
  name: string
  price: number
}

type SubscriptionItemProps = {
  badge?: string
  label: string
  currency: string
  fullPrice: number
  dayPrice: number
  isSelected: boolean
  onClick: () => void
  className?: string
}

export const CURRENCIES: Record<Currency, string> = {
  USD: "$",
  GBP: "£",
  EUR: "€",
}

export function SubscriptionItem(props: SubscriptionItemProps) {
  const borderXClasses = twMerge(
    props.isSelected ? "text-violet" : "text-gray-50 lg:text-gray-300",
    "absolute w-full h-1.5 left-0",
  )
  const borderYClasses = twMerge(
    props.isSelected ? "text-violet" : "text-gray-50 lg:text-gray-300",
    "absolute w-1 h-full",
  )
  const wrapperClasses = twMerge(
    props.isSelected
      ? "bg-violet lg:text-black"
      : "bg-gray-50 lg:bg-gray-300 lg:text-white",
    "h-[78px] w-full relative mb-5 cursor-pointer p-4 lg:mb-6",
    "flex items-center justify-between",
  )

  return (
    <div className={wrapperClasses} onClick={props.onClick}>
      {props.badge && (
        <div className="px-3 h-[20px] flex items-center justify-center absolute right-3 -top-2.5 bg-black text-white z-10 text-sm">
          <FormattedMessage id={props.badge} />
        </div>
      )}
      <div>
        <div className="uppercase font-sans-bold text-xl">
          <FormattedMessage id={props.label} />
        </div>
        <div className="text-base">
          {props.fullPrice} {props.currency}
        </div>
      </div>
      <div>
        <div className="flex items-end">
          <div className="font-sans-bold text-[14px] mr-[3px] mb-[3px]">
            {props.currency}
          </div>
          <div className="font-sans-bold text-2xl">{props.dayPrice}</div>
        </div>
        <div
          className={twMerge(
            "text-base text-gray-250 lg:text-white",
            props.isSelected && "lg:text-black",
          )}
        >
          <FormattedMessage id="paygate.per.day" />
        </div>
      </div>
      <BorderX className={twMerge(borderXClasses, "-top-[5px]")} />
      <BorderX
        className={twMerge(borderXClasses, "-bottom-[5px] -scale-100")}
      />
      <BorderY className={twMerge(borderYClasses, "-left-0.5")} />
      <BorderY className={twMerge(borderYClasses, "-right-0.5 -scale-x-100")} />
    </div>
  )
}

export function OfferTimer() {
  const [timeLeft, setTimeLeft] = useState(60 * 60 * 6)
  const interval = useRef<number>(0)

  const hours = () => Math.floor(timeLeft / (60 * 60))
  const minutes = () => Math.floor((timeLeft - hours() * 60 * 60) / 60)
  const seconds = () => timeLeft - hours() * 60 * 60 - minutes() * 60

  const renderNumber = (count: number) => {
    return count > 9 ? count : `0${count}`
  }

  useEffect(() => {
    interval.current = window.setInterval(() => {
      setTimeLeft((time) => time - 1)
    }, 1000)

    return () => {
      window.clearInterval(interval.current)
      interval.current = 0
    }
  }, [])

  useEffect(() => {
    if (timeLeft <= 0) {
      window.clearInterval(interval.current)
      interval.current = 0
    }
  }, [timeLeft])

  return (
    <div className="px-4 mb-4 lg:hidden">
      <div className="w-full h-[26px] bg-red-200 text-white text-sm flex items-center justify-center font-sans-bold">
        <FormattedMessage id="paygate.timer.text" /> {renderNumber(hours())}:
        {renderNumber(minutes())}:{renderNumber(seconds())}
      </div>
    </div>
  )
}

export const SUCCESS_URL = `${window.location.origin}/payment-success`
export const DECLINE_URL = `${window.location.origin}/payment-failed`

export function Paygate(props: PaygateProps) {
  const [apiBundles, setBundles] = useState<ApiBundle[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isBundlesFetching, setIsBundlesFetching] = useState(false)
  const [activeItem, setActiveItem] = useState<SubscriptionDuration>("month")
  const [region, setRegion] = useState<PaymentRegion | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {
    screen: { bundles, hideTimer },
  } = props

  const getFullPrice = (duration: SubscriptionDuration) => {
    return (
      apiBundles.find(
        (bundle) => bundle.name === bundles![duration][region!].name.cardpay,
      )?.price || 0
    )
  }

  const getDayPrice = (duration: SubscriptionDuration) => {
    const daysCount = {
      week: 7,
      month: 30.5,
      year: 365,
    }

    return Number((getFullPrice(duration) / daysCount[duration]).toFixed(2))
  }

  const getCurrency = (duration: SubscriptionDuration) => {
    return CURRENCIES[
      apiBundles.find(
        (bundle) => bundle.name === bundles![duration][region!].name.cardpay,
      )?.currency || "USD"
    ]
  }

  const fetchData = async () => {
    try {
      if (props.screen.bundles) {
        setIsBundlesFetching(true)
        const { additionalInfo } = await soul.system.getFeatures()
        const paymentRegion = detectUserRegion(additionalInfo)
        setRegion(paymentRegion)
        const bundleNames = (
          ["year", "month", "week"] as SubscriptionDuration[]
        ).reduce<string[]>(
          (acc, cur) => [
            ...acc,
            props.screen.bundles![cur][paymentRegion].name.paypal,
            props.screen.bundles![cur][paymentRegion].name.cardpay,
          ],
          [],
        )
        const { results } = await soul.purchases.getBundles(
          bundleNames,
          undefined,
          ["cardpay", "paypal"],
        )

        setBundles(results)
      }
    } catch (e: any) {
      const alias = e?.response?.body?.error?.alias
      const status = e?.status
      props.onPaymentError(alias, status)
    } finally {
      setIsBundlesFetching(false)
    }
  }

  const onPurchaseClick = async (store: "cardpay" | "paypal") => {
    try {
      setIsLoading(true)
      const bundleName = props.screen.bundles![activeItem][region!].name[store]

      const apiBundle = apiBundles.find((bundle) => bundle.name === bundleName)!

      sendAmplitudeEvent("purchase_started", {
        onboarding: props.surveyId,
        currency: apiBundle.currency,
        payment_method: store,
        price: apiBundle.price,
      })

      trackMetaEvent("Lead")
      trackSnapchatPurchaseClick(
        apiBundle.price,
        apiBundle.currency,
        bundleName,
      )

      if (bundleName) {
        const { redirect_url } = await soul.purchases.purchaseBundle({
          store,
          bundle: bundleName,
          successUrl: SUCCESS_URL,
          declineUrl: DECLINE_URL,
        })

        const purchaseWindow = window.open(
          redirect_url,
          "",
          "width=700,height=600",
        )

        //@ts-ignore
        window.onPurchaseSuccess = () => {
          trackMetaEvent("Purchase", {
            value: `${apiBundle.price}`,
            currency: apiBundle.currency,
          })

          trackSnapchatPurchaseSuccess(
            apiBundle.price,
            apiBundle.currency,
            bundleName,
          )

          sendGooglePurchaseConversion(apiBundle.price, apiBundle.currency)

          if (props.screen.withEverflow && localStorage.getItem("uhns")) {
            sendAmplitudeEvent("Converted male nb user")
            sendEverflowConversion(`${activeItem}ly`)
          }

          purchaseWindow?.close()
          props.onSuccess()
        }
      }
    } catch (e: any) {
      const alias = e?.response?.body?.error?.alias
      const status = e?.status
      props.onPaymentError(alias, status)
    } finally {
      setIsLoading(false)
    }
  }

  const getPaymentUrl = async (store: "cardpay" | "paypal", bundle: string) => {
    const { redirect_url } = await soul.purchases.purchaseBundle({
      store,
      bundle,
      successUrl: SUCCESS_URL,
      declineUrl: DECLINE_URL,
    })
    return redirect_url
  }

  const onPurchaseClickIOS = (store: "cardpay" | "paypal") => {
    setIsLoading(true)
    const bundleName = props.screen.bundles![activeItem][region!].name[store]
    const apiBundle = apiBundles.find((bundle) => bundle.name === bundleName)!

    sendAmplitudeEvent("purchase_started", {
      onboarding: props.surveyId,
      currency: apiBundle.currency,
      payment_method: store,
      price: apiBundle.price,
    })

    trackMetaEvent("Lead")
    trackSnapchatPurchaseClick(apiBundle.price, apiBundle.currency, bundleName)

    const purchaseWindow = window.open("", "", "width=700,height=600")

    //@ts-ignore
    window.onPurchaseSuccess = () => {
      trackMetaEvent("Purchase", {
        value: `${apiBundle.price}`,
        currency: apiBundle.currency,
      })

      trackSnapchatPurchaseSuccess(
        apiBundle.price,
        apiBundle.currency,
        bundleName,
      )

      sendGooglePurchaseConversion(apiBundle.price, apiBundle.currency)

      if (props.screen.withEverflow && localStorage.getItem("uhns")) {
        sendAmplitudeEvent("Converted male nb user")
        sendEverflowConversion(`${activeItem}ly`)
      }

      purchaseWindow?.close()
      props.onSuccess()
    }

    getPaymentUrl(store, bundleName)
      .then((redirectUrl) => {
        purchaseWindow!.location = redirectUrl
        setIsLoading(false)
      })
      .catch((e: any) => {
        const alias = e?.response?.body?.error?.alias
        const status = e?.status
        props.onPaymentError(alias, status)
        setIsLoading(false)
      })
  }

  const handlePurchaseClick = (store: "cardpay" | "paypal") => {
    if (isSafari) {
      onPurchaseClickIOS(store)
    } else {
      onPurchaseClick(store)
    }
  }

  const onContinueClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderTitle = (withRef: boolean) => (
    <div
      className={twMerge(
        "w-[calc(100%+32px)] -ml-4 relative",
        "lg:flex lg:flex-col lg:items-center lg:w-auto lg:m-0",
        props.type === "paymentButtonsBottom" && "bg-black",
      )}
      ref={withRef ? scrollRef : undefined}
    >
      <img
        src={coverVioletImg}
        width="480"
        alt=""
        className="hidden lg:inline-block mb-16"
      />
      <div className="max-w-screen-sm mx-auto text-white">
        <div className="font-pure text-4xl tracking-[2px] text-center mb-1 lg:text-[48px] lg:mb-4">
          <FormattedMessage id="paygate.title" />
        </div>
        <div className="text-center max-w-[255px] mx-auto mb-2 text-sm lg:mb-0 lg:text-2xl">
          <FormattedMessage id="paygate.subtitle" />
        </div>
      </div>
    </div>
  )

  const renderPaymentButtons = () => (
    <div className="w-[calc(100%+32px)] bg-white -ml-4 relative pt-11 lg:w-auto lg:max-w-[410px] lg:p-9 lg:bg-transparent">
      <img
        src={borderImg}
        className="absolute top-[-2px] w-full h-[56px] lg:hidden"
      />
      <img
        src={borderPaymentButtonsDesktop}
        className="hidden lg:block absolute w-full h-full left-0 top-0"
      />
      <div
        className={twMerge(
          "max-w-screen-sm mx-auto z-10 relative",
          props.type === "paymentButtonsBottom" && "px-2",
        )}
      >
        {!hideTimer && <OfferTimer />}
        <div className="pb-6 px-4 lg:p-0">
          {(!bundles || !region || isBundlesFetching) && (
            <div className="mb-5 flex items-center justify-center h-[274px] w-full">
              <Spinner className="animate-spin lg:text-white" />
            </div>
          )}
          {!!bundles && !!region && !isBundlesFetching && (
            <div className="mb-5">
              <SubscriptionItem
                label={bundles.year[region].label}
                currency={getCurrency("year")}
                fullPrice={getFullPrice("year")}
                dayPrice={getDayPrice("year")}
                isSelected={activeItem === "year"}
                onClick={() => setActiveItem("year")}
              />
              <SubscriptionItem
                label={bundles.month[region].label}
                currency={getCurrency("month")}
                badge="paygate.bestseller"
                fullPrice={getFullPrice("month")}
                dayPrice={getDayPrice("month")}
                isSelected={activeItem === "month"}
                onClick={() => setActiveItem("month")}
              />
              <SubscriptionItem
                label={bundles.week[region].label}
                currency={getCurrency("week")}
                fullPrice={getFullPrice("week")}
                dayPrice={getDayPrice("week")}
                isSelected={activeItem === "week"}
                onClick={() => setActiveItem("week")}
              />
            </div>
          )}
          {/* For local prices
            <div className="mb-5">
              <SubscriptionItem
                label="1 Year"
                currency="$"
                fullPrice={44.99}
                dayPrice={0.12}
                isSelected={activeItem === "year"}
                onClick={() => setActiveItem("year")}
              />
              <SubscriptionItem
                label="1 Year"
                currency="$"
                fullPrice={44.99}
                dayPrice={0.12}
                badge="paygate.bestseller"
                isSelected={activeItem === "month"}
                onClick={() => setActiveItem("month")}
              />
              <SubscriptionItem
                label="1 Year"
                currency="$"
                fullPrice={44.99}
                dayPrice={0.12}
                isSelected={activeItem === "week"}
                onClick={() => setActiveItem("week")}
              />
            </div>
          */}
          <div>
            <div className="text-xs text-center mb-5 text-gray-250 px-10 lg:mb-7">
              <FormattedMessage
                id="paygate.agreement"
                values={{
                  recurring: (
                    <a
                      href="https://pure.app/content/en/legal/recurring"
                      className="font-serif-italic hover:text-white"
                      target="_blank"
                    >
                      <FormattedMessage id="paygate.agreement.recurring" />
                    </a>
                  ),
                  terms: (
                    <a
                      href="https://pure.app/content/en/terms"
                      className="font-serif-italic hover:text-white"
                      target="_blank"
                    >
                      <FormattedMessage id="paygate.agreement.terms" />
                    </a>
                  ),
                }}
              />
            </div>
            <button
              className="w-full h-[60px] border border-night flex items-center justify-center cursor-pointer mb-3 lg:bg-white hover:opacity-90"
              onClick={() => handlePurchaseClick("cardpay")}
              disabled={isLoading || isBundlesFetching}
            >
              <img src={mastercardLogo} className="w-[28px]" />
              <img src={visaLogo} className="w-[28px] ml-[3px] mr-3" />
              <div className="text-sm uppercase font-sans-bold">
                <FormattedMessage id="paygate.methods.card" />
              </div>
            </button>
            <button
              className="w-full h-[60px] border border-night flex items-center justify-center cursor-pointer lg:bg-white hover:opacity-90"
              onClick={() => handlePurchaseClick("paypal")}
              disabled={isLoading || isBundlesFetching}
            >
              <img src={paypalLogo} className="h-[22px]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  const renderWhatYouGet = () => {
    const buttonsBottom = props.type === "paymentButtonsBottom"

    return (
      <div
        className={twMerge(
          "w-[calc(100%+32px)] bg-gray-400 -ml-4",
          "lg:bg-white lg:py-8 lg:mt-0",
          buttonsBottom && "-mt-4",
        )}
      >
        <div
          className={twMerge(
            "max-w-screen-sm mx-auto px-8 py-6 text-white",
            "lg:relative lg:flex lg:items-center lg:justify-center lg:max-w-[980px] lg:min-h-[457px] lg:p-0",
            buttonsBottom && "px-6",
          )}
        >
          {buttonsBottom && (
            <img
              src={coverImage}
              className="w-[269px] mx-auto mt-5 mb-6 lg:hidden"
            />
          )}
          <BenefitsBg className="hidden lg:block w-full absolute top-0 left-0" />
          <div className="font-pure text-3xl tracking-wide mb-5 text-center w-[190px] mx-auto lg:relative lg:flex lg:items-center lg:justify-center lg:w-[352px] lg:h-[352px] lg:m-0 lg:text-5xl lg:text-black lg:mr-6">
            <BenefitsTitleBubble className="hidden lg:block absolute top-0 left-0 w-full" />
            <span className="relative lg:inline-block lg:max-w-[212px]">
              <FormattedMessage id="paygate.benefits.title" />
            </span>
          </div>
          <div>
            {Array.from(Array(6).keys()).map((i) => (
              <div
                key={i}
                className="flex items-start tracking-wide mb-2 text-sm lg:relative lg:text-2xl lg:text-black"
              >
                <img
                  src={buttonsBottom ? checkIconPink : checkIcon}
                  className="w-[13px] h-[13px] mt-[1px] mr-2 lg:hidden"
                />
                <img
                  src={checkIconWhite}
                  className="hidden w-5 mr-4 mt-[2px] lg:inline-block"
                />
                <FormattedMessage id={`paygate.benefits.${i + 1}`} />
              </div>
            ))}
          </div>
          {buttonsBottom && (
            <PrimaryButton
              theme="white"
              className="mt-6 lg:hidden"
              onClick={onContinueClick}
            >
              <FormattedMessage id="continue" />
            </PrimaryButton>
          )}
        </div>
      </div>
    )
  }

  const renderReviews = () => (
    <div className="w-[calc(100%+32px)] bg-black -ml-4 text-white">
      <div className="text-center w-[228px] mx-auto font-pure text-3xl tracking-wide my-6">
        <FormattedMessage id="paygate.reviews.title" />
      </div>
      <div
        className={twMerge(
          "w-full max-w-screen-sm mx-auto px-8 pb-8",
          props.type === "paymentButtonsBottom" && "px-6",
        )}
      >
        {Array.from(Array(4).keys()).map((i) => (
          <div className="relative p-5 mb-3 text-black" key={`review-${i}`}>
            <ReviewsBorder className="absolute w-full h-full text-violet left-0 top-0" />
            <div className="flex items-start z-10 relative w-full">
              <img
                src={[avatar1, avatar2, avatar3, avatar4][i]}
                className="w-[54px] h-[54px] mr-4"
              />
              <div className="text-sm">
                <img src={starsIcon} className="w-[60px] mb-1" />
                <div className="font-sans-bold">
                  <FormattedMessage id={`paygate.reviews.${i + 1}.title`} />
                </div>
                <div>
                  <FormattedMessage id={`paygate.reviews.${i + 1}.text`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderReviewsDesktop = () => (
    <div className="lg:bg-white lg:text-black">
      <div className="text-center mx-auto font-pure tracking-wide lg:w-auto lg:my-12 lg:text-5xl">
        <FormattedMessage id="paygate.reviews.title" />
      </div>
      <div
        className={twMerge(
          "mx-auto",
          "lg:grid lg:grid-rows-2-auto lg:grid-cols-2 lg:gap-x-6 lg:gap-y-7 lg:w-auto lg:max-w-[968px] lg:pb-24",
        )}
      >
        {[0, 2, 1, 3].map((i) => (
          <div
            className="relative mb-3 text-black lg:py-6 lg:px-8 lg:mb-0"
            key={`review-${i}`}
          >
            <ReviewsBorder className="absolute w-full h-full text-violet left-0 top-0 lg:text-gray-50" />
            <div className="flex items-start z-10 relative w-full">
              <img
                src={[avatar1, avatar2, avatar3, avatar4][i]}
                className="lg:w-20 lg:h-20 lg:mr-8"
              />
              <div className="lg:text-xl lg:leading-7">
                <img src={starsIcon} className="lg:w-[89px] lg:mb-2" />
                <div className="font-sans-bold">
                  <FormattedMessage id={`paygate.reviews.${i + 1}.title`} />
                </div>
                <div>
                  <FormattedMessage id={`paygate.reviews.${i + 1}.text`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderFeaturedBrands = () => (
    <div className="w-[calc(100%+32px)] bg-white -ml-4 text-black">
      <div
        className={twMerge(
          "w-full max-w-screen-sm mx-auto pt-[30px] pb-11 px-8",
          props.type === "paymentButtonsBottom" && "px-6",
        )}
      >
        <div className="text-center text-xl mb-4 font-sans-bold uppercase">
          <FormattedMessage id="paygate.featured.title" />
        </div>
        <img src={featuredImg} className="w-[255px] mx-auto" />
      </div>
    </div>
  )

  const renderFeaturedBrandsDesktop = () => (
    <div className="w-[calc(100%+32px)] bg-gray-400 text-white">
      <div className={twMerge("w-full mx-auto pt-[62px] pb-[86px]")}>
        <div className="text-center text-[48px] mb-8 font-pure uppercase">
          <FormattedMessage id="paygate.featured.title.desktop" />
        </div>
        <img className="mx-auto" src={featuredDesktopImg} width="621" alt="" />
      </div>
    </div>
  )

  const renderTerms = () => {
    const buttonsBottom = props.type === "paymentButtonsBottom"

    return (
      <div
        className={twMerge(
          "w-[calc(100%+32px)] bg-gray-400 -ml-4 text-white",
          "lg:pb-[120px] lg:bg-gray-400 lg:text-white",
          buttonsBottom && "bg-white text-black",
        )}
      >
        <div
          className={twMerge(
            "w-full max-w-screen-sm mx-auto pt-6 pb-14 px-8 lg:max-w-[941px] lg:p-0",
            buttonsBottom && "px-6",
          )}
        >
          <div className="w-full relative p-6 pb-8 lg:px-10 lg:py-10">
            <ReviewsBorder
              className={twMerge(
                "absolute w-full h-full text-black left-0 top-0",
                "lg:hidden",
                buttonsBottom && "text-gray-50",
              )}
            />
            <TermsDesktopBorder
              className={twMerge(
                "hidden absolute w-full h-full text-black left-0 top-0",
                "lg:block",
              )}
            />
            <div className="relative z-10">
              <p className="mb-4 text-sm lg:text-[18px] lg:leading-[22px]">
                <FormattedMessage
                  id="paygate.info.1"
                  values={{
                    email: (
                      <span className="whitespace-nowrap">
                        <FormattedMessage id="paygate.info.email" />
                      </span>
                    ),
                  }}
                />
              </p>
              <p className="mb-4 text-sm lg:text-[18px] lg:leading-[22px]">
                <FormattedMessage
                  id="paygate.info.2"
                  values={{
                    date: new Date().toLocaleDateString("en-us", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }),
                  }}
                />
              </p>
              <p className="mb-4 text-sm lg:text-[18px] lg:leading-[22px]">
                <FormattedMessage id="paygate.info.3" />
              </p>
              <p className="mb-4 text-sm lg:text-[18px] lg:leading-[22px]">
                <FormattedMessage id="paygate.info.4" />
              </p>
              <p className="mb-4 text-sm lg:text-[18px] lg:leading-[22px]">
                <FormattedMessage id="paygate.info.5" />
              </p>
              <a
                href="https://pure.app/content/en/terms"
                target="_blank"
                className="uppercase text-sm font-sans-bold block lg:text-xl lg:my-3 lg:pt-3 lg:hover:opacity-80"
              >
                <FormattedMessage id="paygate.info.terms" />
              </a>
              <a
                href="https://pure.app/content/en/privacy"
                target="_blank"
                className="uppercase text-sm font-sans-bold block mt-3 lg:mt-0 lg:text-xl lg:hover:opacity-80"
              >
                <FormattedMessage id="paygate.info.privacy" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderDesktopLogo = () => (
    <div className="pt-8 pl-10 pb-6 bg-black">
      <Logo className="h-5" color="white" />
    </div>
  )

  const renderDesktopFirstBlock = () => (
    <div className="lg:flex items-center justify-center gap-24 pb-7 bg-black">
      {renderTitle(false)}
      {renderPaymentButtons()}
    </div>
  )

  const renderWhiteDivider = () => (
    <div className="w-full bg-black">
      <WhiteBottomDivider className="w-[calc(100vw+32px)] -ml-8 -mb-4" />
    </div>
  )

  const renderMobile = () => {
    switch (props.type) {
      case "paymentButtonsBottom":
        return (
          <div className="flex flex-col h-full w-full -mt-4 lg:hidden">
            {renderWhatYouGet()}
            {renderFeaturedBrands()}
            {renderReviews()}
            {renderTitle(true)}
            {renderPaymentButtons()}
            {renderTerms()}
          </div>
        )
      // type === "paymentButtonsTop"
      default:
        return (
          <div className="flex flex-col h-full w-full -mt-4 lg:hidden">
            {renderTitle(false)}
            {renderPaymentButtons()}
            {renderWhatYouGet()}
            {renderReviews()}
            {renderFeaturedBrands()}
            {renderTerms()}
          </div>
        )
    }
  }

  const renderDesktop = () => (
    <div className="hidden absolute top-0 left-0 w-full overflow-x-hidden lg:block">
      {renderDesktopLogo()}
      {renderDesktopFirstBlock()}
      {renderWhiteDivider()}
      {renderWhatYouGet()}
      {renderReviewsDesktop()}
      {renderFeaturedBrandsDesktop()}
      {renderTerms()}
    </div>
  )

  return (
    <>
      {renderMobile()}
      {renderDesktop()}
    </>
  )
}
