import { FormattedMessage } from "react-intl"
import { Checkbox } from "components/atoms"
import { useState } from "react"

type MultiSelectProps = {
  options: string[]
  selected: string[]
  setSelected: (value: string[]) => void
  theme?: "default" | "gray"
}

export function MultiSelect(props: MultiSelectProps) {
  const { options, theme } = props
  const { selected, setSelected } = props

  const onClick = (value: string) => {
    const set = new Set(selected)
    if (set.has(value)) {
      set.delete(value)
    } else {
      set.add(value)
    }
    setSelected(Array.from(set))
  }

  return (
    <div className="flex flex-wrap gap-x-3 gap-y-1 justify-center">
      {options.map((label) => (
        <Checkbox
          key={label}
          selected={selected.includes(label)}
          onClick={() => onClick(label)}
          theme={theme}
        >
          <FormattedMessage id={label} defaultMessage={label} />
        </Checkbox>
      ))}
    </div>
  )
}
