const isGoogleAdsActive = window.location.origin.includes(
  "poll.anythingbutboring.app",
)

export const sendGooglePurchaseConversion = (
  value: number,
  currency: string,
) => {
  //@ts-ignore
  if (isGoogleAdsActive && window.gtag) {
    //@ts-ignore
    window.gtag("event", "conversion", {
      send_to: "AW-11021254447/RT0mCN_V0cEZEK_-q4cp",
      value,
      currency,
      transaction_id: "",
    })
  }
}

export const sendGoogleRegConversion = () => {
  //@ts-ignore
  if (isGoogleAdsActive && window.gtag) {
    //@ts-ignore
    window.gtag("event", "conversion", {
      send_to: "AW-11021254447/f_SBCOLV0cEZEK_-q4cp",
    })
  }
}

export const sendGoogleClickConversion = (callback?: () => void) => {
  //@ts-ignore
  if (isGoogleAdsActive && window.gtag) {
    //@ts-ignore
    window.gtag("event", "conversion", {
      send_to: "AW-11021254447/iXL-CN-_lMgZEK_-q4cp",
      event_callback: callback,
    })
  } else {
    callback && callback()
  }
}
