import headerImgEn from "assets/images/v57/header-en.webp"
import infoImgEn from "assets/images/v57/info-en.png"

export const v57ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
  },
  info: {
    en: infoImgEn,
  },
}
