import { useState, useEffect } from "react"
import type { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

export type MessageContainerProps = {
  isMine?: boolean
  className?: string
  children: ReactNode
}

export function MessageContainer(props: MessageContainerProps) {
  const defaultClass = "rounded-bl-none bg-black text-white mr-4"
  const mineClass = "rounded-br-none bg-white text-black ml-4"

  const resolvedClass = props.isMine ? mineClass : defaultClass

  return (
    <div
      className={`flex ${props.isMine ? "justify-end" : "justify-start"} w-full mt-2`}
    >
      <div
        className={twMerge(
          "rounded-2xl text-xl leading-6",
          resolvedClass,
          props.className,
        )}
      >
        {props.children}
      </div>
    </div>
  )
}

export type TextMessageProps = {
  messageId: string
  postfix?: string
  isMine?: boolean
}

export function TextMessage(props: TextMessageProps) {
  return (
    <MessageContainer className="p-3" isMine={props.isMine}>
      <FormattedMessage
        id={props.messageId}
        values={{
          b: (msg) => <b className="font-sans-bold">{msg}</b>,
        }}
      />
      {props.postfix}
    </MessageContainer>
  )
}

export function TypingMessage() {
  const [dots, setDots] = useState<number>(1)

  useEffect(() => {
    const interval = window.setInterval(() => {
      setDots((dots) => dots + 1)
    }, 200)

    return () => window.clearInterval(interval)
  }, [setDots])

  return (
    <MessageContainer className="p-3 text-gray-200">
      <FormattedMessage id="v3.chat.typing" />
      <span className="inline-block min-w-4">{".".repeat(dots % 4)}</span>
    </MessageContainer>
  )
}

export type ImageMessageProps = {
  src: string
  isMine?: boolean
}

export function ImageMessage(props: ImageMessageProps) {
  return (
    <MessageContainer
      className="w-[180px] h-[180px] overflow-hidden"
      isMine={props.isMine}
    >
      <img className="w-full h-full object-cover" src={props.src} alt="" />
    </MessageContainer>
  )
}
