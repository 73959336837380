import { useEffect, useRef, useState } from "react"
import type { ReactNode } from "react"
import { Screen, Gender, Sexuality } from "config/types"
import { Logo } from "components/atoms"
import { ProgressBar } from "components/molecules"
import {
  MultipleChoiceQuestion,
  SingleChoiceQuestion,
} from "components/templates"
import { GenderQuestion } from "./gender-question"
import { SexualityQuestion } from "./sexuality-question"
import { ZodiakQuestion } from "./zodiak-question"
import { TurnonsQuestion } from "./turnons-question"
import { LikeQuestion } from "./like-question"
import { Transition, TransitionStatus } from "react-transition-group"
import { twMerge } from "tailwind-merge"
import {
  getCompletedStepId,
  saveCompletedSteps,
  saveChatData,
  readChatData,
} from "lib/localStorage"
import { Auth } from "./auth"
import { Paygate } from "./paygate"
import { Stub } from "./stub"
import { SuccessScreen } from "./success-screen"
import { MatchStub } from "./match-stub"
import { sendAmplitudeEvent } from "lib/amplitude"
import { AnalyzeScreen } from "./analyze-screen"
import { Chat } from "./chat/chat"
import { SingleChoicePicturesQuestion } from "components/templates/single-choice-pictures-question"
import { ReviewStub } from "./review-stub"
import { IntroQuestion } from "./intro-question"
import { RandomLikeQuestion } from "./random-like-question"
import { Map } from "./map"

import { ReactComponent as BorderBackground } from "../../assets/images/survey-border-bg.svg"

type SurveyProps = {
  steps: Array<Screen[]>
  StartScreen?: React.ComponentType<{ onClick: () => void }>
  surveyId: string
}

export type MessageData = {
  text?: string
  src?: string
  isMine?: boolean
}

export type ChatData = {
  questionIdx: number
  messages: MessageData[]
  gender: Gender
  sexuality: Sexuality
}

export function Survey(props: SurveyProps) {
  const { StartScreen } = props
  const [step, setStep] = useState<number>(getCompletedStepId(props.surveyId))
  const [chatData, setChatData] = useState<ChatData>(
    readChatData<MessageData>(props.surveyId),
  )
  const stepsRef = useRef<(HTMLElement | null)[]>([])

  const questions: Screen[] = props.steps.reduce(
    (result, stage) => [...result, ...stage],
    [],
  )
  const currentStep = questions[step]
  const {
    bgColor,
    textColor,
    logoColor,
    progressBarTheme,
    hideProgressBar,
    noMaxHeight,
    noMaxWidth,
    borderColor,
    showMapBackground,
  } = currentStep
  const styleProp = {
    ...(bgColor && { backgroundColor: bgColor }),
    ...(textColor && { color: textColor }),
  }

  const goNext = (
    formCid?: string,
    reply?: string | number | string[] | number[],
    screenIndex?: number,
  ) => {
    sendAmplitudeEvent("screen_completed", {
      onboarding: props.surveyId,
      screen_cid: currentStep.id,
      screen_index: step,
      form_cid: formCid,
      reply,
    })
    setStep(screenIndex || step + 1)
    saveCompletedSteps(props.surveyId, screenIndex || step + 1)
  }

  const onAuthButtonClick = (provider: string) => {
    sendAmplitudeEvent("registration_button_clicked", {
      onboarding: props.surveyId,
      screen_cid: currentStep.id,
      screen_index: step,
      provider,
    })
  }

  const onAuthError = (alias?: string, status?: number) => {
    sendAmplitudeEvent("funnel_registration_error", {
      onboarding: props.surveyId,
      status_code: status,
      error_message: alias,
    })
  }

  const onPaymentError = (alias?: string, status?: number) => {
    sendAmplitudeEvent("funnel_payment_error", {
      onboarding: props.surveyId,
      status_code: status,
      error_message: alias,
    })
  }

  const updateChatData = (data: Partial<ChatData>) => {
    setChatData((oldData) => ({
      ...oldData,
      ...data,
    }))
  }

  const renderQuestion = (screen: Screen, status: TransitionStatus) => {
    const redirectToSuccessScreen = () => {
      const idx = questions.findIndex((i) => i.type === "payment-success")
      goNext(undefined, undefined, idx)
    }

    const isActive = questions.findIndex((i) => i.id === screen.id) === step

    switch (screen.type) {
      case "gender": {
        return <GenderQuestion screen={screen} onClick={goNext} />
      }
      case "sexuality": {
        return <SexualityQuestion screen={screen} onClick={goNext} />
      }
      case "single": {
        return <SingleChoiceQuestion screen={screen} onClick={goNext} />
      }
      case "single_with_pictures": {
        return <SingleChoicePicturesQuestion screen={screen} onClick={goNext} />
      }
      case "multiple": {
        return <MultipleChoiceQuestion screen={screen} onClick={goNext} />
      }
      case "zodiak": {
        return <ZodiakQuestion screen={screen} onClick={goNext} />
      }
      case "turnons": {
        return <TurnonsQuestion screen={screen} onClick={goNext} />
      }
      case "like": {
        return <LikeQuestion screen={screen} onClick={goNext} />
      }
      case "chat": {
        return (
          <Chat
            screen={screen}
            goNext={goNext}
            transitionStatus={status}
            chatData={chatData}
            updateChatData={updateChatData}
          />
        )
      }
      case "auth": {
        return (
          <Auth
            screen={screen}
            onSuccess={() => goNext()}
            redirectToSuccessScreen={redirectToSuccessScreen}
            onButtonClick={onAuthButtonClick}
            onError={onAuthError}
          />
        )
      }
      case "paygate": {
        return (
          <Paygate
            type={screen.paygateType}
            screen={screen}
            onSuccess={() => goNext()}
            surveyId={props.surveyId}
            onPaymentError={onPaymentError}
          />
        )
      }
      case "stub": {
        return <Stub screen={screen} onClick={() => goNext()} />
      }
      case "review_stub": {
        return <ReviewStub screen={screen} onClick={() => goNext()} />
      }
      case "payment-success": {
        return <SuccessScreen surveyId={props.surveyId} />
      }
      case "match-stub": {
        return (
          <MatchStub
            screen={screen}
            onClick={() => goNext()}
            isActive={isActive}
          />
        )
      }
      case "analyze": {
        return (
          <AnalyzeScreen
            screen={screen}
            isActive={isActive}
            onClick={() => goNext()}
          />
        )
      }
      case "intro": {
        return <IntroQuestion screen={screen} onClick={goNext} />
      }
      case "random_like": {
        return (
          <RandomLikeQuestion step={step} screen={screen} onClick={goNext} />
        )
      }
    }
  }

  const renderSteps = () => {
    return questions.map((item, idx) => {
      const inProp = idx === step

      return (
        <Transition
          nodeRef={{ current: stepsRef.current[idx] }}
          in={inProp}
          mountOnEnter={item.type === "chat"}
          timeout={200}
          key={idx}
        >
          {(status) => (
            <div
              ref={(el) => (stepsRef.current[idx] = el)}
              className={twMerge(
                "opacity-0 hidden w-full",
                !item.skipTransition &&
                  "transition-opacity ease-in duration-200",
                status === "entered" && "block opacity-1",
                status === "exiting" && "block opacity-0",
                status === "exited" && "hidden opacity-0",
                !borderColor && "grow",
              )}
            >
              {renderQuestion(item, status)}
            </div>
          )}
        </Transition>
      )
    })
  }

  const renderBorder = (children: ReactNode) => (
    <div className="relative h-[484px] w-[343px]">
      <BorderBackground
        className="absolute top-0 left-0 w-full h-full"
        style={{ color: borderColor }}
      />
      <div className="relative px-6 py-8">
        <div className="flex justify-center relative">
          <Logo className="h-5" color={logoColor} />
        </div>
        {children}
      </div>
    </div>
  )

  useEffect(() => {
    if (step === 0) {
      sendAmplitudeEvent("onboarding_started", {
        onboarding: props.surveyId,
      })
    }

    if (currentStep.action) {
      switch (currentStep.action) {
        case "checkout":
          sendAmplitudeEvent("checkout_screen_presented", {
            onboarding: props.surveyId,
            screen_cid: currentStep.id || "screen_paywall",
            screen_index: step,
          })
          break
        case "registration":
          sendAmplitudeEvent("registration_screen_presented", {
            onboarding: props.surveyId,
            screen_cid: currentStep.id || "screen_registration",
            screen_index: step,
          })
          break
        case "purchase_success":
          sendAmplitudeEvent("purchase_completed_screen_presented", {
            onboarding: props.surveyId,
            screen_cid: currentStep.id || "screen_success",
            screen_index: step,
          })
          break
      }
    } else {
      sendAmplitudeEvent("screen_presented", {
        onboarding: props.surveyId,
        screen_cid: currentStep.id,
        screen_index: step,
      })
    }
  }, [step])

  useEffect(() => {
    const listener = () => {
      if (currentStep.type === "chat" || currentStep.type == "match-stub") {
        saveChatData(props.surveyId, chatData)
      }
    }

    window.addEventListener("beforeunload", listener)

    return () => window.removeEventListener("beforeunload", listener)
  }, [chatData, step, currentStep])

  useEffect(() => {
    document.body.style.backgroundColor = currentStep.bgColor || "unset"
  }, [currentStep.bgColor])

  if (currentStep.type === "start") {
    if (StartScreen) {
      return <StartScreen onClick={goNext} />
    }
  }

  return (
    <div
      className={twMerge(
        "relative flex flex-col items-center justify-center w-full h-full transition-colors duration-200 ease-in",
      )}
      style={styleProp}
    >
      {showMapBackground && (
        <Map className="absolute top-0 left-0 w-full h-full" />
      )}
      <div
        className={twMerge(
          "p-4 height-700:pt-2 z-10",
          !noMaxHeight && "max-h-[100%]",
          noMaxWidth && "w-full",
          !borderColor && "h-full",
          showMapBackground && "z-[500]",
        )}
      >
        {!hideProgressBar && (
          <div className="flex justify-center">
            <Logo color={logoColor} />
          </div>
        )}
        <div
          className={twMerge(
            "pt-5 flex flex-col items-center h-full gap-5 grow-1 height-700:pt-2 height-700:gap-3",
            noMaxWidth ? "w-full" : "max-w-screen-sm",
            borderColor && "justify-center pt-0 height-700:pt-0",
          )}
        >
          <Transition in={!hideProgressBar} timeout={200}>
            {(state) =>
              state === "entered" || state === "exiting" ? (
                <ProgressBar
                  step={step}
                  steps={props.steps}
                  theme={progressBarTheme}
                />
              ) : null
            }
          </Transition>
          {borderColor ? renderBorder(renderSteps()) : renderSteps()}
        </div>
      </div>
    </div>
  )
}
