import { PrimaryButton } from "components/molecules"
import { Screen } from "config/types"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

type StubProps = {
  screen: Screen
  onClick: () => void
}

export function Stub(props: StubProps) {
  const {
    screen: { text, image, title, buttonTheme },
    onClick,
  } = props

  return (
    <div className="flex flex-col h-full">
      <div>
        {image && (
          <img
            src={image}
            className={twMerge(
              "w-full m-auto mt-8 mb-8 height-650:max-w-[80%]",
              !!title && "h-[200px] w-auto height-575:h-[130px]",
            )}
          />
        )}
      </div>
      {!title && (
        <div className="grow flex items-center justify-center">
          <div className="text-2xl text-center max-w-[310px] mx-auto">
            <FormattedMessage id={text} />
          </div>
        </div>
      )}
      {!!title && (
        <div className="grow flex flex-col items-center justify-start">
          <div className="text-2xl text-center max-w-[310px] mx-auto mb-4">
            <FormattedMessage id={title} />
          </div>
          <div className="text-sm text-center">
            <FormattedMessage id={text} />
          </div>
        </div>
      )}
      <div className="w-full text-center pt-3 mb-6">
        <PrimaryButton onClick={onClick} theme={buttonTheme}>
          <FormattedMessage id="continue" />
        </PrimaryButton>
      </div>
    </div>
  )
}
