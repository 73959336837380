import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import App from "./App"

import "./index.css"
import { OAuth } from "pages/oauth"
import { PaymentSuccessHandler } from "components/templates/payment-success-handler"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path=":locale/*" element={<App />} />
        <Route path="oauth/:provider" element={<OAuth />} />
        <Route path="payment-success" element={<PaymentSuccessHandler />} />
      </Routes>
    </BrowserRouter>
  </>,
)
