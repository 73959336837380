import { Screen } from "config/types"
import { FormattedMessage } from "react-intl"
import Lottie, { LottieRefCurrentProps } from "lottie-react"
import matchesJSON from "assets/animations/Match_Loop.json"
import { PrimaryButton } from "components/molecules"
import { useEffect, useRef } from "react"
import { twMerge } from "tailwind-merge"

type MatchStubProps = {
  screen: Screen
  onClick: () => void
  isActive: boolean
}

export function MatchStub(props: MatchStubProps) {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const {
    screen: { title, text, buttonTheme, values, image },
    onClick,
    isActive,
  } = props

  useEffect(() => {
    if (isActive && lottieRef.current) {
      lottieRef.current.play()
    }

    if (!isActive && lottieRef.current) {
      lottieRef.current.stop()
    }
  }, [isActive])
  return (
    <div className="flex flex-col grow h-full px-4">
      <div className="grow flex flex-col items-center justify-center">
        <div
          className={twMerge(
            !image
              ? "w-[194px] h-[245px]"
              : "max-h-[230px] height-650:max-h-[190px] height-600:max-h-[170px]",
          )}
        >
          {!image && (
            <Lottie
              animationData={matchesJSON}
              loop={true}
              autoplay={false}
              lottieRef={lottieRef}
            />
          )}
          {image && (
            <img
              src={image}
              className="h-full max-h-[230px] height-650:max-h-[190px] height-600:max-h-[170px]"
            />
          )}
        </div>
        <div className="font-pure text-3xl text-center tracking-[2px] my-8 height-575:my-4">
          <FormattedMessage id={title || text} values={values} />
          {!!(title && text) && (
            <div className="font-sans text-base mt-3 tracking-normal height-575:text-sm">
              <FormattedMessage id={text} />
            </div>
          )}
        </div>
      </div>
      <div className="pt-6 pb-4 height-575:pt-0">
        <PrimaryButton onClick={onClick} theme={buttonTheme}>
          <FormattedMessage id="continue" />
        </PrimaryButton>
      </div>
    </div>
  )
}
