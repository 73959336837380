import UAParser from "ua-parser-js"

const userAgent = new UAParser()

export const isIOS = userAgent.getOS().name === "iOS"
export const isAndroid = Boolean(userAgent.getOS().name?.includes("Android"))
export const isSafari = userAgent.getBrowser().name?.includes("Safari")

export const MOBILE_BRAKEPOINT = 768
export const TABLET_BRAKEPOINT = 1024
export const screenWidth =
  window.innerWidth > 0 ? window.innerWidth : screen.width

export const isDesktop = screenWidth >= TABLET_BRAKEPOINT
