import { twMerge } from "tailwind-merge"

import LogoPng from "assets/images/logo.png"
import LogoWhitePng from "assets/images/logo-white.png"

type LogoProps = {
  className?: string
  color?: "white" | "black" | "transparent"
}

export function Logo({ className, color }: LogoProps) {
  return (
    <img
      src={color === "white" ? LogoWhitePng : LogoPng}
      className={twMerge(
        "h-3.5",
        className,
        color === "transparent" && "opacity-0",
      )}
    />
  )
}
