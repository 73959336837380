import { twMerge } from "tailwind-merge"

import type { DetailedHTMLProps, HTMLAttributes } from "react"

import TopPng from "assets/images/top-background.png"
import BottomPng from "assets/images/bottom-background.png"

type BackgroundTemplateProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export function BackgroundTemplate(props: BackgroundTemplateProps) {
  const { className, children, ...rest } = props
  return (
    <div className={twMerge("h-screen relative", className)} {...rest}>
      <img
        className="absolute top-0 right-0 w-[183px] md:w-[277px] lg:w-auto"
        src={TopPng}
      />
      <img
        className="absolute bottom-0 left-0 w-[198px] md:w-[238px] lg:w-auto"
        src={BottomPng}
      />
      {children}
    </div>
  )
}
