import headerImgEn from "assets/images/v57-nd-pb/header-en.webp"
import infoImgEn from "assets/images/v57-nd-pb/info-en.webp"

export const v57ndpbConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
  },
  info: {
    en: infoImgEn,
  },
}
