import headerImgEn from "assets/images/store-page/header-en.webp"
import infoImgEn from "assets/images/store-page/info-en.webp"
import headerImgRu from "assets/images/store-page/header-ru.webp"
import infoImgRu from "assets/images/store-page/info-ru.webp"
import headerImgFr from "assets/images/store-page/header-fr.webp"
import infoImgFr from "assets/images/store-page/info-fr.webp"
import headerImgDe from "assets/images/store-page/header-de.webp"
import infoImgDe from "assets/images/store-page/info-de.webp"
import { ReactComponent as StarImg } from "assets/images/store-page/star.svg"
import { generateAppsFlyerLink } from "lib/common"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { sendAmplitudeEvent } from "lib/amplitude"
import { trackMetaEvent } from "lib/meta"
import { trackSnapchatClick } from "lib/snapchat"
import { sendGoogleClickConversion } from "lib/google"
import { twMerge } from "tailwind-merge"

type StorePageProps = {
  surveyId: string
  images?: Record<string, Record<string, string>>
  buttonClassName?: string
}

const images: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
    ru: headerImgRu,
    fr: headerImgFr,
    de: headerImgDe,
  },
  info: {
    en: infoImgEn,
    ru: infoImgRu,
    fr: infoImgFr,
    de: infoImgDe,
  },
}

export function StorePage(props: StorePageProps) {
  const { locale = "en" } = useParams()
  const imagesList = props.images || images

  const onButtonClick = () => {
    trackMetaEvent("Lead")
    trackSnapchatClick()
    sendGoogleClickConversion(() => {
      location.assign(generateAppsFlyerLink(props.surveyId))
    })
  }

  useEffect(() => {
    sendAmplitudeEvent("onboarding_started", {
      onboarding: props.surveyId,
    })
  }, [])

  return (
    <div className="max-w-[450px] mx-auto pt-[72px] px-4 pb-10">
      <img src={imagesList.header[locale] || imagesList.header.en} />
      <button
        onClick={onButtonClick}
        className={twMerge(
          "h-[56px] w-full rounded-[45px] bg-blue mt-2 mb-3 text-lg text-white font-sans-bold",
          locale === "de" && "rounded-[16px]",
          props.buttonClassName,
        )}
      >
        <FormattedMessage id="store_page.button" />
      </button>
      <img src={imagesList.info[locale] || imagesList.info.en} />
      {[2, 3, 4].map((reviewIndex) => (
        <div
          key={`review-${reviewIndex}`}
          className="px-5 py-3 rounded-[16px] bg-gray-store-review mt-4"
        >
          <div className="font-sans-bold text-[22px] leading-6">
            <FormattedMessage id={`paygate.reviews.${reviewIndex}.title`} />
          </div>
          <div className="flex mt-1.5">
            {Array.from(Array(5).keys()).map((i) => (
              <StarImg
                className="w-4 h-4 mr-1.5 text-star-icon"
                key={`star-${i}`}
              />
            ))}
          </div>
          <div className="opacity-40 mt-3 text-lg">
            <FormattedMessage id={`paygate.reviews.${reviewIndex}.text`} />
          </div>
        </div>
      ))}
    </div>
  )
}
