import { twMerge } from "tailwind-merge"

import { Screen } from "config/types"

import { ReactComponent as LineSvg } from "assets/images/progressbar-line-clippath.svg"
import { ReactComponent as DotSvg } from "assets/images/progressbar-dot.svg"

type ProgressBar = {
  step: number
  steps: Array<Screen[]>
  theme?: "white" | "violet"
}

export function ProgressBar(props: ProgressBar) {
  const { step, steps } = props

  // amount of sections on steps
  const sectionsCount = steps.length
  // amount of screens that needs to be completed for dots
  const sectionsBorders = steps.reduce(
    (result, current, index) => [...result, result[index] + current.length],
    [0],
  )
  // total amount of screens in survey
  const stepsCount = sectionsBorders[sectionsCount]

  let progress = 0
  if (step === 0) {
    // 3% is cut from both sides of progressbar because of design
    progress = 3
  } else if (step === stepsCount) {
    progress = 100
  } else {
    const sectionIndex = sectionsBorders.findIndex((value) => step < value) - 1
    const sectionLength = steps[sectionIndex].length
    const sectionStep = step - sectionsBorders[sectionIndex]
    const sectionMaxProgress = 100 / sectionsCount
    progress = Math.ceil(
      (sectionIndex + sectionStep / sectionLength) * sectionMaxProgress,
    )
  }

  const isSectionCompleted = (index: number) => {
    return step >= sectionsBorders[index]
  }

  const completedSectionClassName =
    props.theme === "white" ? "text-white" : "text-violet"

  return (
    <div className="relative w-[308px]">
      <LineSvg />
      <div
        className={twMerge(
          props.theme !== "white" &&
            `bg-gradient-to-r from-violet from-0% via-[#BBADF7_${progress}%] to-[#333_${progress}%]`,
          props.theme === "white" &&
            `bg-gradient-to-r from-white from-0% via-[#FFFFFF_${progress}%] to-[#333_${progress}%]`,
          "h-[13px] w-full progress-bar-clip-path absolute top-0",
        )}
      ></div>
      <div className="relative flex justify-between px-2">
        {[...Array(sectionsCount + 1)].map((_, index) => (
          <DotSvg
            key={index}
            className={
              isSectionCompleted(index)
                ? completedSectionClassName
                : "text-gray-300"
            }
          />
        ))}
      </div>
    </div>
  )
}
