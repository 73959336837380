import { FormattedMessage } from "react-intl"
import { SpaceButton } from "components/molecules"

import type { Screen } from "config/types"

import { ReactComponent as ButtonSvg } from "assets/images/start-button.svg"
import bgImg from "assets/images/intro-map-bg.png"

type IntroQuestionProps = {
  screen: Screen
  onClick: (formCid: string) => void
}

export function IntroQuestion(props: IntroQuestionProps) {
  const {
    screen: { title, buttonText },
    onClick,
  } = props

  return (
    <div className="flex flex-col justify-start items-center">
      <div className="relative flex justify-center items-center w-[309px] h-[248px] mt-12 mb-9 mx-auto">
        <img src={bgImg} className="w-full h-full absolute" />
        <h2 className="relative text-4xl leading-[34px] uppercase font-pure text-black text-center max-w-48">
          <FormattedMessage
            id={title}
            values={{
              br: () => <br />,
            }}
          />
        </h2>
      </div>
      <SpaceButton message={buttonText} onClick={() => onClick("intro")} />
    </div>
  )
}
