export const trackMetaEvent = (
  eventName: string,
  eventParams?: Record<string, number | string>,
) => {
  if (
    !window.location.origin.includes("test") &&
    !window.location.origin.includes("localhost")
  ) {
    if (eventName !== "Lead" || !sessionStorage.getItem("fb_lead_tracked")) {
      //@ts-ignore
      if (typeof window.fbq === "function") {
        //@ts-ignore
        window.fbq("track", eventName, eventParams)

        if (eventName === "Lead") {
          sessionStorage.setItem("fb_lead_tracked", "1")
        }
      }
    }
  }
}
