import coverImg from "assets/images/v49/cover.webp"
import titleImg from "assets/images/v49/title.webp"
import { generateAppsFlyerLink } from "lib/common"
import { FormattedMessage } from "react-intl"
import { useEffect } from "react"
import { sendAmplitudeEvent } from "lib/amplitude"
import { trackMetaEvent } from "lib/meta"
import { trackSnapchatClick } from "lib/snapchat"
import { sendGoogleClickConversion } from "lib/google"

type PageWithCoverProps = {
  surveyId: string
  images?: Record<string, string>
}

const images: Record<string, string> = {
  cover: coverImg,
  title: titleImg,
}

export function PageWithCover(props: PageWithCoverProps) {
  const imagesList = props.images || images

  const onButtonClick = () => {
    trackMetaEvent("Lead")
    trackSnapchatClick()
    sendGoogleClickConversion(() => {
      location.assign(generateAppsFlyerLink(props.surveyId))
    })
  }

  useEffect(() => {
    sendAmplitudeEvent("onboarding_started", {
      onboarding: props.surveyId,
    })
  }, [])

  return (
    <div
      className="relative max-w-[450px] h-full mx-auto pb-[50px] bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${imagesList.cover})` }}
    >
      <div className="absolute bottom-[50px] left-0 right-0 px-4">
        <img src={imagesList.title} />
        <button
          className="bg-[#FF00FF] text-white w-full h-[56px] text-[18px] uppercase font-sans-bold tracking-wide"
          onClick={onButtonClick}
        >
          <FormattedMessage id="button.download" />
        </button>
      </div>
    </div>
  )
}
