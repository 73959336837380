import type { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  ChatGenderButton,
  CurveBorderedButton,
  ZodiakSigns,
} from "../../molecules"
import type { ChatGenderQuestion, Gender } from "config/types"

export type QuestionContainerProps = {
  children: ReactNode
  className?: string
  showHint?: boolean
}

function QuestionContainer(props: QuestionContainerProps) {
  return (
    <div className={twMerge("flex justify-end mt-5 ml-4", props.className)}>
      <div>
        {props.showHint && (
          <span className="mb-1 text-sm">
            <FormattedMessage id="v3.chat.choose" />
          </span>
        )}
        <div className="max-w-[340px]">{props.children}</div>
      </div>
    </div>
  )
}

export type GenderQuestionProps = {
  handleSelect: (gender: Gender, text?: string) => void
} & Required<Pick<ChatGenderQuestion, "options">>

export function GenderQuestion(props: GenderQuestionProps) {
  return (
    <QuestionContainer showHint>
      {props.options.map((option) => (
        <ChatGenderButton
          className="mt-2"
          gender={option.gender}
          onClick={() => props.handleSelect(option.gender, option.label)}
          key={option.gender}
        >
          <FormattedMessage
            id={option.label}
            values={{
              b: (msg) => <b className="font-sans-bold">{msg}</b>,
            }}
          />
        </ChatGenderButton>
      ))}
    </QuestionContainer>
  )
}

export type SingleQuestionProps = {
  options: string[]
  handleSelect: (text?: string) => void
}

export function SingleQuestion(props: SingleQuestionProps) {
  return (
    <QuestionContainer showHint>
      {props.options.map((option) => (
        <CurveBorderedButton
          className="mt-2 first:mt-1 w-full"
          onClick={() => props.handleSelect(option)}
          key={option}
        >
          <FormattedMessage id={option} />
        </CurveBorderedButton>
      ))}
    </QuestionContainer>
  )
}

export type ZodiakQuestionProps = {
  handleSelect: (text?: string) => void
}

export function ZodiakQuestion(props: ZodiakQuestionProps) {
  return (
    <QuestionContainer className="-ml-2 -mr-1">
      <ZodiakSigns
        buttonClassName="text-white group-hover:text-violet"
        onClick={props.handleSelect}
      />
    </QuestionContainer>
  )
}
