import type { Screen } from "./types"

import GenderImg from "assets/images/questions/gender.png"
import SexualityImg from "assets/images/questions/sexuality.png"
import AgeImg from "assets/images/questions/age.png"
import Age2Img from "assets/images/questions/age-2.png"
import LanguagesImg from "assets/images/questions/languages.png"

import Profile1F from "assets/images/profile-map-1-f.png"
import Profile2F from "assets/images/profile-map-2-f.png"
import Profile3F from "assets/images/profile-map-3-f.png"
import Profile4F from "assets/images/profile-map-4-f.png"

import Profile1M from "assets/images/profile-map-1-m.png"
import Profile2M from "assets/images/profile-map-2-m.png"
import Profile3M from "assets/images/profile-map-3-m.png"
import Profile4M from "assets/images/profile-map-4-m.png"

export const SURVEY_CONFIG_MAP1: Array<Screen[]> = [
  [
    {
      type: "intro",
      title: "map.intro.title",
      buttonText: "map.intro.button",
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      textColor: "white",
      logoColor: "white",
      id: "screen_intro",
    },
    {
      type: "gender",
      version: "map",
      text: "gender.question",
      image: GenderImg,
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      textColor: "white",
      logoColor: "white",
      id: "screen_gender",
    },
    {
      type: "sexuality",
      version: "map",
      text: "map.sexuality.question",
      options: ["h", "g", "l", "b", "q"],
      image: SexualityImg,
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      textColor: "white",
      logoColor: "white",
      id: "screen_sexuality",
    },
    {
      type: "single",
      version: "map",
      text: "map.age.question",
      image: AgeImg,
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      textColor: "white",
      logoColor: "white",
      options: ["18-24", "25-29", "30-39", "40-49", "50+"],
      id: "screen_age",
    },
    {
      type: "single",
      version: "map",
      text: "languages.question",
      image: Age2Img,
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      textColor: "white",
      logoColor: "white",
      options: [
        "languages.ch",
        "languages.es",
        "languages.de",
        "languages.en",
        "languages.fr",
        "languages.hi",
      ],
      id: "screen_languages",
    },
    {
      type: "single",
      version: "map",
      text: "map.turnons.question",
      image: LanguagesImg,
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      textColor: "white",
      logoColor: "white",
      options: [
        "turnons.kisses",
        "turnons.biting",
        "turnons.dirtytalk",
        "turnons.edging",
        "turnons.scents",
        "turnons.footfetish",
      ],
      id: "screen_turnons",
    },
    {
      type: "random_like",
      version: "map",
      imagesByGender: {
        f: [Profile1F, Profile2F, Profile3F, Profile4F],
        m: [Profile1M, Profile2M, Profile3M, Profile4M],
      },
      text: "like.question",
      hideProgressBar: true,
      borderColor: "rgba(0, 0, 0, 0.9)",
      bgColor: "#2C292E",
      showMapBackground: true,
      skipTransition: true,
      textColor: "white",
      logoColor: "white",
      id: "screen_random_like",
    },
  ],
  [
    {
      type: "auth",
      textColor: "#000000",
      hideProgressBar: true,
      logoColor: "transparent",
      action: "registration",
      showFacebook: true,
      id: "screen_registration",
    },
    {
      type: "paygate",
      paygateType: "paymentButtonsTop",
      withEverflow: true,
      hideProgressBar: true,
      noMaxHeight: true,
      noMaxWidth: true,
      action: "checkout",
      id: "screen_paywall",
      bgColor: "#000000",
      bundles: {
        year: {
          us: {
            name: {
              paypal: "pure-web.year.paypal.us",
              cardpay: "pure-web.year.cardpay.us",
            },
            label: "paygate.subscription.year",
          },
          uk: {
            name: {
              paypal: "pure-web.year.paypal.uk",
              cardpay: "pure-web.year.cardpay.uk",
            },
            label: "paygate.subscription.year",
          },
          eu: {
            name: {
              paypal: "pure-web.year.paypal.eu",
              cardpay: "pure-web.year.cardpay.eu",
            },
            label: "paygate.subscription.year",
          },
          row: {
            name: {
              paypal: "pure-web.year.paypal.row",
              cardpay: "pure-web.year.cardpay.row",
            },
            label: "paygate.subscription.year",
          },
        },
        month: {
          us: {
            name: {
              paypal: "pure-web.monthly.paypal.us",
              cardpay: "pure-web.monthly.cardpay.us",
            },
            label: "paygate.subscription.month",
          },
          uk: {
            name: {
              paypal: "pure-web.monthly.paypal.uk",
              cardpay: "pure-web.monthly.cardpay.uk",
            },
            label: "paygate.subscription.month",
          },
          eu: {
            name: {
              paypal: "pure-web.monthly.paypal.eu",
              cardpay: "pure-web.monthly.cardpay.eu",
            },
            label: "paygate.subscription.month",
          },
          row: {
            name: {
              paypal: "pure-web.monthly.paypal.row",
              cardpay: "pure-web.monthly.cardpay.row",
            },
            label: "paygate.subscription.month",
          },
        },
        week: {
          us: {
            name: {
              paypal: "pure-web.weekly.paypal.us",
              cardpay: "pure-web.weekly.cardpay.us",
            },
            label: "paygate.subscription.week",
          },
          uk: {
            name: {
              paypal: "pure-web.weekly.paypal.uk",
              cardpay: "pure-web.weekly.cardpay.uk",
            },
            label: "paygate.subscription.week",
          },
          eu: {
            name: {
              paypal: "pure-web.weekly.paypal.eu",
              cardpay: "pure-web.weekly.cardpay.eu",
            },
            label: "paygate.subscription.week",
          },
          row: {
            name: {
              paypal: "pure-web.weekly.paypal.row",
              cardpay: "pure-web.weekly.cardpay.row",
            },
            label: "paygate.subscription.week",
          },
        },
      },
    },
    {
      type: "payment-success",
      hideProgressBar: true,
      action: "purchase_success",
      noMaxWidth: true,
    },
  ],
]
