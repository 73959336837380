import { twMerge } from "tailwind-merge"

import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react"

import { ReactComponent as BorderX } from "assets/images/border-x.svg"
import { ReactComponent as BorderY } from "assets/images/border-y.svg"

export type ButtonTheme =
  | "violet"
  | "white"
  | "black"
  | "gray"
  | "bordered"
  | "darkgray"

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  theme?: ButtonTheme
  shadow?: boolean
  flat?: boolean
  noHover?: boolean
}

const themeClasses: Record<ButtonTheme, { button: string; border: string }> = {
  violet: {
    button: "bg-violet text-black group hover:opacity-80",
    border: "text-violet group-hover:opacity-80",
  },
  white: {
    button: "bg-white text-black",
    border: "text-white",
  },
  black: {
    button: "bg-black text-white hover:opacity-80",
    border: "text-black",
  },
  gray: {
    button: "bg-gray-50 text-black hover:opacity-80",
    border: "text-gray-50",
  },
  bordered: {
    button: "bg-white text-black hover:opacity-80 border border-black",
    border: "",
  },
  darkgray: {
    button: "bg-darkgray text-white hover:opacity-80",
    border: "text-darkgray",
  },
}

export function Button(props: ButtonProps) {
  const {
    className,
    children,
    shadow,
    theme = "black",
    flat,
    noHover,
    ...restProps
  } = props

  const classes = themeClasses[theme]
  const borderXClasses = twMerge(classes.border, "absolute w-full h-1.5")
  const borderYClasses = twMerge(classes.border, "absolute w-1 h-full")

  return (
    <div
      className={twMerge("px-0.5 py-1.5 w-full transition-colors", className)}
    >
      <button
        className={twMerge(
          "relative w-full",
          classes.button,
          shadow && "shadow-glow",
        )}
        {...restProps}
      >
        {!flat && (
          <>
            <BorderX className={twMerge(borderXClasses, "-top-[5px]")} />
            <BorderX
              className={twMerge(borderXClasses, "-bottom-[5px] -scale-100")}
            />
            <BorderY className={twMerge(borderYClasses, "-left-0.5")} />
            <BorderY
              className={twMerge(borderYClasses, "-right-0.5 -scale-x-100")}
            />
          </>
        )}
        {children}
      </button>
    </div>
  )
}
