import type { Screen } from "./types"

import GenderImg from "assets/images/questions/gender.png"
import SexualityImg from "assets/images/questions/sexuality.png"
import AgeImg from "assets/images/questions/age.png"
import Age2Img from "assets/images/questions/age-2.png"
import LanguagesImg from "assets/images/questions/languages.png"
import FirstDateImg from "assets/images/questions/first-date.png"
import OpenRelationshipImg from "assets/images/questions/open-relationship.png"
import Turnons2Img from "assets/images/questions/turnons2.png"
import Turnons3Img from "assets/images/questions/turnons3.png"
import TurnonsBedroomImg from "assets/images/questions/turnons-bedroom.png"
import ConversationsImg from "assets/images/questions/conversations.png"
import HairImg from "assets/images/questions/hair.png"
import PartnerQualitiesImg from "assets/images/questions/partner-qualities.png"
import DressStyleImg from "assets/images/questions/dress-style.png"
import TravelImg from "assets/images/questions/travel-1.png"
import Travel2Img from "assets/images/questions/travel-2.png"
import LifestyleImg from "assets/images/questions/lifestyle.png"
import DevilImg from "assets/images/questions/devil.png"

export const SURVEY_CONFIG_V1: Array<Screen[]> = [
  [
    {
      type: "start",
      id: "screen_intro",
    },
    {
      type: "gender",
      text: "gender.question",
      image: GenderImg,
      id: "screen_gender",
    },
    {
      type: "sexuality",
      text: "sexuality.question",
      image: SexualityImg,
      id: "screen_sexuality",
    },
    {
      type: "stub",
      text: "stub.pure",
      image: DevilImg,
      id: "screen_pure",
    },
    {
      type: "single",
      text: "age.question1",
      image: AgeImg,
      options: ["18-24", "25-29", "30-39", "40-49", "50+"],
      id: "screen_age",
    },
    {
      type: "single",
      text: "age.question2",
      image: Age2Img,
      options: ["20-24", "25-30", "31-40", "41-50", "50+"],
      id: "screen_age_range",
    },
    {
      type: "single",
      text: "first_date.question",
      image: FirstDateImg,
      options: ["first_date.1", "first_date.2", "first_date.3"],
      id: "screen_first_date",
    },
    {
      type: "single",
      text: "open_relationship.question",
      image: OpenRelationshipImg,
      options: [
        "open_relationship.1",
        "open_relationship.2",
        "open_relationship.3",
      ],
      id: "screen_open_relationships",
    },
    {
      type: "multiple",
      text: "languages.question",
      image: LanguagesImg,
      options: [
        "languages.en",
        "languages.es",
        "languages.de",
        "languages.fr",
        "languages.hi",
        "languages.ch",
      ],
      id: "screen_languages",
    },
    {
      type: "zodiak",
      text: "zodiak.question",
      id: "screen_zodiac",
    },
    {
      type: "match-stub",
      text: "match_stub.match.text",
      progressBarTheme: "white",
      bgColor: "#EDD4A6",
      values: {
        users: 77,
        compatibility: 46,
      },
      id: "screen_possible_match_1",
    },
  ],
  [
    {
      type: "turnons",
      text: "turnons.question",
      id: "screen_turnons",
    },
    {
      type: "single",
      text: "turnons2.question",
      image: Turnons2Img,
      options: ["turnons2.1", "turnons2.2", "turnons2.3"],
      id: "screen_sparks",
    },
    {
      type: "like",
      text: "like.question",
      id: "screen_photo_1",
    },
    {
      type: "like",
      text: "like.question",
      id: "screen_photo_2",
    },
    {
      type: "like",
      text: "like.question",
      id: "screen_photo_3",
    },
    {
      type: "like",
      text: "like.question",
      id: "screen_photo_4",
    },
    {
      type: "single",
      text: "turnons3.question",
      image: Turnons3Img,
      options: ["turnons3.1", "turnons3.2", "turnons3.3"],
      id: "screen_dominant",
    },
    {
      type: "single",
      text: "conversations.question",
      image: ConversationsImg,
      options: ["conversations.1", "conversations.2", "conversations.3"],
      id: "screen_conversations",
    },
    {
      type: "match-stub",
      text: "match_stub.plain.text",
      progressBarTheme: "white",
      bgColor: "#EDD4A6",
      id: "screen_matches_placeholder",
    },
    {
      type: "single",
      text: "hair.question",
      image: HairImg,
      options: ["hair.1", "hair.2", "hair.3"],
      id: "screen_hair",
    },
    {
      type: "single",
      text: "partner_qualities.question",
      image: PartnerQualitiesImg,
      options: [
        "partner_qualities.1",
        "partner_qualities.2",
        "partner_qualities.3",
      ],
      id: "screen_qualities",
    },
    {
      type: "single",
      text: "dress_style.question",
      image: DressStyleImg,
      options: ["dress_style.1", "dress_style.2", "dress_style.3"],
      id: "screen_dress_style",
    },
    {
      type: "single",
      text: "travel.question",
      image: TravelImg,
      options: ["travel.1", "travel.2"],
      id: "screen_travel_country",
    },
    {
      type: "single",
      text: "travel2.question",
      image: Travel2Img,
      options: ["travel2.1", "travel2.2"],
      id: "screen_travel_abroad",
    },
    {
      type: "multiple",
      text: "lifestyle.question",
      image: LifestyleImg,
      options: [
        "lifestyle.1",
        "lifestyle.2",
        "lifestyle.3",
        "lifestyle.4",
        "lifestyle.5",
        "lifestyle.6",
      ],
      id: "screen_lifestyle",
    },
    {
      type: "multiple",
      text: "turnons_bedroom.question",
      image: TurnonsBedroomImg,
      options: [
        "turnons_bedroom.1",
        "turnons_bedroom.2",
        "turnons_bedroom.3",
        "turnons_bedroom.4",
        "turnons_bedroom.5",
        "turnons_bedroom.6",
      ],
      id: "screen_bedroom_activity",
    },
  ],
  [
    {
      type: "match-stub",
      text: "match_stub.match.text",
      progressBarTheme: "white",
      bgColor: "#EDD4A6",
      values: {
        users: 32,
        compatibility: 88,
      },
      id: "screen_possible_match_2",
    },
    {
      type: "auth",
      textColor: "#000000",
      logoColor: "transparent",
      action: "registration",
      showFacebook: true,
      id: "screen_registration",
    },
    {
      type: "paygate",
      paygateType: "paymentButtonsTop",
      withEverflow: true,
      hideProgressBar: true,
      noMaxHeight: true,
      noMaxWidth: true,
      action: "checkout",
      id: "screen_paywall",
      bgColor: "#000000",
      bundles: {
        year: {
          us: {
            name: {
              paypal: "pure-web.year.paypal.us",
              cardpay: "pure-web.year.cardpay.us",
            },
            label: "paygate.subscription.year",
          },
          uk: {
            name: {
              paypal: "pure-web.year.paypal.uk",
              cardpay: "pure-web.year.cardpay.uk",
            },
            label: "paygate.subscription.year",
          },
          eu: {
            name: {
              paypal: "pure-web.year.paypal.eu",
              cardpay: "pure-web.year.cardpay.eu",
            },
            label: "paygate.subscription.year",
          },
          row: {
            name: {
              paypal: "pure-web.year.paypal.row",
              cardpay: "pure-web.year.cardpay.row",
            },
            label: "paygate.subscription.year",
          },
        },
        month: {
          us: {
            name: {
              paypal: "pure-web.monthly.paypal.us",
              cardpay: "pure-web.monthly.cardpay.us",
            },
            label: "paygate.subscription.month",
          },
          uk: {
            name: {
              paypal: "pure-web.monthly.paypal.uk",
              cardpay: "pure-web.monthly.cardpay.uk",
            },
            label: "paygate.subscription.month",
          },
          eu: {
            name: {
              paypal: "pure-web.monthly.paypal.eu",
              cardpay: "pure-web.monthly.cardpay.eu",
            },
            label: "paygate.subscription.month",
          },
          row: {
            name: {
              paypal: "pure-web.monthly.paypal.row",
              cardpay: "pure-web.monthly.cardpay.row",
            },
            label: "paygate.subscription.month",
          },
        },
        week: {
          us: {
            name: {
              paypal: "pure-web.weekly.paypal.us",
              cardpay: "pure-web.weekly.cardpay.us",
            },
            label: "paygate.subscription.week",
          },
          uk: {
            name: {
              paypal: "pure-web.weekly.paypal.uk",
              cardpay: "pure-web.weekly.cardpay.uk",
            },
            label: "paygate.subscription.week",
          },
          eu: {
            name: {
              paypal: "pure-web.weekly.paypal.eu",
              cardpay: "pure-web.weekly.cardpay.eu",
            },
            label: "paygate.subscription.week",
          },
          row: {
            name: {
              paypal: "pure-web.weekly.paypal.row",
              cardpay: "pure-web.weekly.cardpay.row",
            },
            label: "paygate.subscription.week",
          },
        },
      },
    },
    {
      type: "payment-success",
      hideProgressBar: true,
      action: "purchase_success",
      noMaxWidth: true,
    },
  ],
]
