import { FormattedMessage, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"
import { PrimaryButton, CurveBorderedButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

type SingleChoiceQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string) => void
}

const GRID_OPTIONS: Record<string, { container: string; items: string[] }> = {
  5: {
    container: "grid-rows-2 grid-cols-6",
    items: [
      "col-span-3",
      "col-span-3",
      "col-span-2",
      "col-span-2",
      "col-span-2",
    ],
  },
  6: {
    container: "grid-rows-2 grid-cols-[repeat(3,_auto)]",
    items: [],
  },
}

export function SingleChoiceQuestion(props: SingleChoiceQuestionProps) {
  const {
    screen: { text, image, buttonTheme, options = [], imageSmaller, version },
    onClick,
  } = props
  const intl = useIntl()

  const twoColumns = options.length > 4

  const getGridOptions = (idx: number) => {
    return GRID_OPTIONS[String(idx)]
  }

  switch (version) {
    case "map":
      const gridOption = GRID_OPTIONS[String(options.length)]

      return (
        <div className="flex flex-col align-center justify-between h-full">
          {image && (
            <img
              src={image}
              width="208.83"
              height="168"
              className="inline-block my-6 mx-auto"
            />
          )}
          {text && (
            <div className="mx-auto mb-6 mx-[-8px] font-serif text-2xl tracking-[2px] text-center uppercase">
              <FormattedMessage
                id={text}
                values={{
                  br: () => <br />,
                }}
              />
            </div>
          )}
          <div
            className={`grid ${gridOption?.container || ""} gap-x-2 gap-y-3 w-full`}
          >
            {options.map((label, idx) => (
              <CurveBorderedButton
                className={`${gridOption?.items[idx] || ""} px-2 min-w-6 h-[44px]`}
                key={label}
                onClick={() =>
                  onClick(
                    props.screen.id,
                    intl.formatMessage({ id: label, defaultMessage: label }),
                  )
                }
              >
                <FormattedMessage id={label} defaultMessage={label} />
              </CurveBorderedButton>
            ))}
          </div>
        </div>
      )
    default:
      return (
        <div className="flex flex-col justify-between h-full">
          <QuestionTitle
            id={text}
            className={twMerge(!image && "min-h-[140px]")}
          />
          <div
            className={twMerge(
              "pb-4 height-730:max-h-[230px]",
              options.length >= 3 && "height-575:max-h-[190px]",
              imageSmaller && "height-730:!max-h-[180px]",
            )}
          >
            {image && (
              <img
                src={image}
                className="height-730:max-h-[100%] height-730:mx-auto height-730:h-full"
              />
            )}
          </div>
          <div
            className={twMerge(
              "flex items-center gap-y-1 pb-4 height-575:gap-y-0",
              twoColumns ? "flex-wrap justify-center" : "flex-col",
            )}
          >
            {options.map((label) => (
              <PrimaryButton
                theme={buttonTheme}
                className={twMerge(
                  twoColumns &&
                    "w-[calc(50%-10px)] odd:mr-2.5 even:ml-2.5 last:!mr-0",
                )}
                key={label}
                onClick={() =>
                  onClick(
                    props.screen.id,
                    intl.formatMessage({ id: label, defaultMessage: label }),
                  )
                }
              >
                <div
                  className={twMerge(
                    "height-575:text-xs",
                    twoColumns &&
                      options.some(
                        (option) =>
                          intl.formatMessage({
                            id: option,
                            defaultMessage: option,
                          }).length > 8,
                      ) &&
                      "text-xs",
                  )}
                >
                  <FormattedMessage id={label} defaultMessage={label} />
                </div>
              </PrimaryButton>
            ))}
          </div>
        </div>
      )
  }
}
