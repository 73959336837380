import { Button } from "components/atoms"

import type { ButtonProps } from "components/atoms"

export function PrimaryButton(props: ButtonProps) {
  const { children, ...restProps } = props
  return (
    <Button {...restProps}>
      <div className="px-1.5 pt-3.5 pb-3 font-sans-bold text-sm uppercase tracking-wide">
        {children}
      </div>
    </Button>
  )
}
