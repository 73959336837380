import type { Screen } from "./types"

import Profile1F from "assets/images/profile-1-f.png"
import Profile2F from "assets/images/profile-2-f.png"
import Profile3F from "assets/images/profile-3-f.png"

import Profile1M from "assets/images/profile-1-m.png"
import Profile2M from "assets/images/profile-2-m.png"
import Profile3M from "assets/images/profile-3-m.png"

export const SURVEY_CONFIG_CHAT4: Array<Screen[]> = [
  [
    {
      type: "chat",
      hideProgressBar: true,
      chatQuestions: [
        {
          label: "v3.message.intro.1",
          id: "intro_1",
          type: "empty",
        },
        {
          label: "v3.message.intro.2",
          id: "intro_2",
          type: "empty",
          isTyping: true,
        },
        {
          label: "v3.message.gender.1",
          id: "gender",
          type: "gender",
          options: [
            { label: "gender.f", gender: "f" },
            { label: "gender.m", gender: "m" },
          ],
        },
        {
          label: "v3.message.sexuality.1",
          id: "sexuality",
          type: "sexuality",
          optionsByGender: {
            m: [
              "v3.option.sexuality.h",
              "v3.option.sexuality.b",
              "v3.option.sexuality.g",
              "v3.option.sexuality.q",
              "v3.option.sexuality.a",
            ],
            f: [
              "v3.option.sexuality.h",
              "v3.option.sexuality.b",
              "v3.option.sexuality.l",
              "v3.option.sexuality.q",
              "v3.option.sexuality.a",
            ],
          },
        },
        {
          label: "v3.message.age.1",
          id: "age",
          type: "single",
          options: ["18-24", "25-29", "30-39", "40-49", "50+"],
        },
        {
          label: "v3.message.range.1",
          id: "range",
          type: "single",
          options: ["18-24", "25-29", "30-39", "40-49", "50+"],
        },
      ],
    },
    {
      type: "match-stub",
      text: "match_stub.match.text",
      bgColor: "#EDD4A6",
      hideProgressBar: true,
      values: {
        users: 77,
        compatibility: 46,
      },
      id: "screen_possible_match_1",
    },
    {
      type: "chat",
      hideProgressBar: true,
      chatQuestions: [
        {
          label: "v3.message.profile.1",
          id: "profile",
          type: "image",
          srcsByGender: {
            f: [Profile1F],
            m: [Profile1M],
          },
          options: ["common.yes", "common.no"],
        },
        {
          label: "v3.message.profile.2",
          id: "profile",
          type: "image",
          srcsByGender: {
            f: [Profile2F],
            m: [Profile2M],
          },
          options: ["common.yes", "common.no"],
        },
        {
          label: "v3.message.profile.3",
          id: "profile",
          type: "image",
          srcsByGender: {
            f: [Profile3F],
            m: [Profile3M],
          },
          options: ["common.yes", "common.no"],
        },
      ],
    },
    {
      type: "match-stub",
      text: "match_stub.match.text",
      bgColor: "#EDD4A6",
      hideProgressBar: true,
      values: {
        users: 32,
        compatibility: 88,
      },
      id: "screen_possible_match_2",
    },
    {
      type: "auth",
      textColor: "#000000",
      hideProgressBar: true,
      showFacebook: true,
      logoColor: "transparent",
      action: "registration",
      id: "screen_registration",
    },
    {
      type: "paygate",
      paygateType: "paymentButtonsBottom",
      withEverflow: true,
      hideProgressBar: true,
      noMaxHeight: true,
      noMaxWidth: true,
      action: "checkout",
      id: "screen_paywall",
      bgColor: "#1A1717",
      bundles: {
        year: {
          us: {
            name: {
              paypal: "pure-web.year.paypal.us",
              cardpay: "pure-web.year.cardpay.us",
            },
            label: "paygate.subscription.year",
          },
          uk: {
            name: {
              paypal: "pure-web.year.paypal.uk",
              cardpay: "pure-web.year.cardpay.uk",
            },
            label: "paygate.subscription.year",
          },
          eu: {
            name: {
              paypal: "pure-web.year.paypal.eu",
              cardpay: "pure-web.year.cardpay.eu",
            },
            label: "paygate.subscription.year",
          },
          row: {
            name: {
              paypal: "pure-web.year.paypal.row",
              cardpay: "pure-web.year.cardpay.row",
            },
            label: "paygate.subscription.year",
          },
        },
        month: {
          us: {
            name: {
              paypal: "pure-web.monthly.paypal.us",
              cardpay: "pure-web.monthly.cardpay.us",
            },
            label: "paygate.subscription.month",
          },
          uk: {
            name: {
              paypal: "pure-web.monthly.paypal.uk",
              cardpay: "pure-web.monthly.cardpay.uk",
            },
            label: "paygate.subscription.month",
          },
          eu: {
            name: {
              paypal: "pure-web.monthly.paypal.eu",
              cardpay: "pure-web.monthly.cardpay.eu",
            },
            label: "paygate.subscription.month",
          },
          row: {
            name: {
              paypal: "pure-web.monthly.paypal.row",
              cardpay: "pure-web.monthly.cardpay.row",
            },
            label: "paygate.subscription.month",
          },
        },
        week: {
          us: {
            name: {
              paypal: "pure-web.weekly.paypal.us",
              cardpay: "pure-web.weekly.cardpay.us",
            },
            label: "paygate.subscription.week",
          },
          uk: {
            name: {
              paypal: "pure-web.weekly.paypal.uk",
              cardpay: "pure-web.weekly.cardpay.uk",
            },
            label: "paygate.subscription.week",
          },
          eu: {
            name: {
              paypal: "pure-web.weekly.paypal.eu",
              cardpay: "pure-web.weekly.cardpay.eu",
            },
            label: "paygate.subscription.week",
          },
          row: {
            name: {
              paypal: "pure-web.weekly.paypal.row",
              cardpay: "pure-web.weekly.cardpay.row",
            },
            label: "paygate.subscription.week",
          },
        },
      },
    },
    {
      type: "payment-success",
      hideProgressBar: true,
      action: "purchase_success",
      noMaxWidth: true,
    },
  ],
]
