import EverflowSDK from "@everflow/everflow-sdk"

if (process.env.REACT_APP_EVERFLOW_TRACKING_DOMAIN) {
  EverflowSDK.configure({
    // You only need to set the tracking domain you want to use
    tracking_domain: process.env.REACT_APP_EVERFLOW_TRACKING_DOMAIN,
  })
}

export const sendEverflowConversion = (
  subscriptionType: "weekly" | "monthly" | "yearly",
) => {
  if (!process.env.REACT_APP_EVERFLOW_TRACKING_DOMAIN) {
    return
  }

  if (window.location.hostname.includes("test")) {
    return
  }

  if (
    !EverflowSDK.urlParameter("offer_id") &&
    !EverflowSDK.urlParameter("aid")
  ) {
    return
  }

  const userId = localStorage.getItem("soulStorage:userId")
  EverflowSDK.conversion({
    offer_id: EverflowSDK.urlParameter("offer_id"),
    aid: EverflowSDK.urlParameter("aid"),
    transaction_id:
      EverflowSDK.urlParameter("subid") ||
      EverflowSDK.urlParameter("transaction_id"),
    affiliate_id: EverflowSDK.urlParameter("sub2"),
    adv1: subscriptionType,
    adv2: userId,
  })
}
