import logoImg from "assets/images/logo-big.png"
import coverImg from "assets/images/success-cover.png"
import coverVioletImg from "assets/images/success-cover-violet.png"
import { PrimaryButton } from "components/molecules"
import { FormattedMessage } from "react-intl"
import borderImg from "assets/images/rounded-border.svg"
import whiteBorderDesktop from "assets/images/white-border-final.png"
import { generateAppsFlyerLink } from "lib/common"
import { Button } from "components/atoms"

type SuccessScreenProps = {
  surveyId: string
}

export function SuccessScreen(props: SuccessScreenProps) {
  return (
    <div className="flex flex-col text-lg text-center h-full max-h-full lg:absolute lg:top-0 lg:left-0 lg:w-full lg:bg-violet-page lg:bg-cover lg:bg-center">
      <div className="flex justify-center w-full max-w-screen-sm mx-auto lg:pt-8 lg:pl-10 lg:block lg:mx-0">
        <img src={logoImg} className="h-[33px] lg:h-5" />
      </div>
      <div className="h-full grow flex flex-col lg:w-full lg:flex-row lg:items-center lg:justify-center">
        <div className="mt-6 mb-9 grow flex items-center justify-center max-w-screen-sm mx-auto height-700:max-h-[233px] height-650:mt-3 height-650:mb-0 lg:m-0 lg:max-w-[498px]">
          <img
            src={coverImg}
            className="w-full max-w-full max-h-full mx-auto height-700:w-auto height-700:h-full lg:hidden"
          />
          <img
            src={coverVioletImg}
            width="498"
            className="hidden lg:inline-block"
          />
        </div>
        <div className="w-[calc(100%+32px)] -ml-4 -mb-4 bg-black mt-14 relative height-700:grow text-white height-575:mt-8  lg:max-w-sm lg:m-0 lg:ml-12 lg:p-12 lg:relative lg:bg-transparent">
          <img
            src={borderImg}
            className="absolute top-[-54px] w-full h-[56px] height-575:top-[-40px] lg:hidden"
          />
          <img
            src={whiteBorderDesktop}
            className="hidden lg:inline-block w-full h-full absolute top-0 left-0"
          />
          <div className="max-w-screen-sm mx-auto relative">
            <div className="text-center my-4 text-2xl font-serif tracking-[2px] uppercase px-8 lg:font-pure lg:text-black lg:text-[40px] lg:leading-[58px] lg:p-0 lg:m-0 lg:mb-4">
              <FormattedMessage id="success.title" />
            </div>
            <div className="text-base mb-8 px-8 lg:p-0 lg:m-0 lg:mb-10 lg:text-2xl lg:text-black">
              <FormattedMessage id="success.description" />
            </div>
            <div className="px-8 pb-8 lg:p-0">
              <a
                onClick={() => {
                  location.assign(generateAppsFlyerLink(props.surveyId))
                }}
                className="block w-full"
              >
                <PrimaryButton theme="white" className="lg:hidden">
                  <FormattedMessage id="success.button" />
                </PrimaryButton>
                <Button
                  flat
                  theme="bordered"
                  className="hidden lg:block w-full m-auto mb-5"
                >
                  <div className="py-5 font-sans-bold text-sm uppercase">
                    <FormattedMessage id="success.button" />
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
