import { FormattedMessage } from "react-intl"

import { Logo } from "../../atoms"
import GoddessAvatar from "assets/images/chat/goddess-avatar.png"

export function Header() {
  return (
    <div className="sticky top-0 w-full box-border flex items-center p-3 bg-white">
      <img src={GoddessAvatar} alt="" width="48" height="48" />
      <span className="inline-block grow ml-3 leading-5">
        <FormattedMessage id="v3.header.goddess" />
      </span>
      <Logo className="mr-1" />
    </div>
  )
}
